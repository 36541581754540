import React, { useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import '../App.css';
import { createQrLabelCall } from 'features/qrLabel/createQrLabel';
import { createBulkQrLabelCall } from 'features/qrLabel/createBulkQrLabel';
import { useTranslation } from 'react-i18next';
import i18n from 'i18n';


const ProductLabelFormMultiLanguage = ({ action }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const t_eng = i18n.getFixedT('en');
    const t_sk = i18n.getFixedT('sk');
    const t_hr = i18n.getFixedT('hr');
    const t_sv = i18n.getFixedT('sv');
    const t_cs = i18n.getFixedT('cs');
    const { qr_account, qr_label_list, qr_label_list_load } = useSelector(state => state.user);
    const { language } = useSelector(state => state.user);
    const [lan, setLan] = useState(language);

    const [formData, setFormData] = useState({
        name: "",
        allergens: "",
        energy_value: "",
        energy_value_kcal: "",
        fat: "",
        saturated_fat: "",
        carbohydrates: "",
        sugar: "",
        protein: "",
        salt: "",
        category: "",
        alcoholic_strength: "",
        provenance: "",
        producer_info: "",
        net_content: "",
        sugar_content: "",
        durability_date: "",
        ingredients: "",
        nutritional_info: "",
        company_name: "",
        company_address: "",
        company_oib: "",
        company_phone: "",
        company_email: "",
        sulfites: "",
        lang: "hr",
        do_not_show: false

    });

    const [formDataEng, setFormDataEng] = useState({
        name: "",
        allergens: "",
        energy_value: "",
        energy_value_kcal: "",
        fat: "",
        saturated_fat: "",
        carbohydrates: "",
        sugar: "",
        protein: "",
        salt: "",
        category: "",
        alcoholic_strength: "",
        provenance: "",
        producer_info: "",
        net_content: "",
        sugar_content: "",
        durability_date: "",
        ingredients: "",
        nutritional_info: "",
        company_name: "",
        company_address: "",
        company_oib: "",
        company_phone: "",
        company_email: "",
        sulfites: "",
        lang: "en",
        do_not_show: false

    });

    const [formDataSk, setFormDataSk] = useState({
        name: "",
        allergens: "",
        energy_value: "",
        energy_value_kcal: "",
        fat: "",
        saturated_fat: "",
        carbohydrates: "",
        sugar: "",
        protein: "",
        salt: "",
        category: "",
        alcoholic_strength: "",
        provenance: "",
        producer_info: "",
        net_content: "",
        sugar_content: "",
        durability_date: "",
        ingredients: "",
        nutritional_info: "",
        company_name: "",
        company_address: "",
        company_oib: "",
        company_phone: "",
        company_email: "",
        sulfites: "",
        lang: "sk",
        do_not_show: false

    });

    const [formDataSv, setFormDataSv] = useState({
        name: "",
        allergens: "",
        energy_value: "",
        energy_value_kcal: "",
        fat: "",
        saturated_fat: "",
        carbohydrates: "",
        sugar: "",
        protein: "",
        salt: "",
        category: "",
        alcoholic_strength: "",
        provenance: "",
        producer_info: "",
        net_content: "",
        sugar_content: "",
        durability_date: "",
        ingredients: "",
        nutritional_info: "",
        company_name: "",
        company_address: "",
        company_oib: "",
        company_phone: "",
        company_email: "",
        sulfites: "",
        lang: "sv",
        do_not_show: false

    });


    const [formDataCs, setFormDataCs] = useState({
        name: "",
        allergens: "",
        energy_value: "",
        energy_value_kcal: "",
        fat: "",
        saturated_fat: "",
        carbohydrates: "",
        sugar: "",
        protein: "",
        salt: "",
        category: "",
        alcoholic_strength: "",
        provenance: "",
        producer_info: "",
        net_content: "",
        sugar_content: "",
        durability_date: "",
        ingredients: "",
        nutritional_info: "",
        company_name: "",
        company_address: "",
        company_oib: "",
        company_phone: "",
        company_email: "",
        sulfites: "",
        lang: "cs",
        do_not_show: false

    });

    const onCheckboxChange = e => {
        const { name, checked } = e.target;
        setFormData({ ...formData, [name]: checked });
    };

    const onCheckboxChangeEn = e => {
        const { name, checked } = e.target;
        setFormDataEng({ ...formDataEng, [name]: checked });
    };

    const onCheckboxChangeSk = e => {
        const { name, checked } = e.target;
        setFormDataSk({ ...formDataSk, [name]: checked });
    };

    const onCheckboxChangeSv = e => {
        const { name, checked } = e.target;
        setFormDataSv({ ...formDataSv, [name]: checked });
    };

    const onCheckboxChangeCs = e => {
        const { name, checked } = e.target;
        setFormDataCs({ ...formDataCs, [name]: checked });
    };

    const onChange = e => {
        setFormData({ ...formData, [e.target.name]: e.target.value });

        if (e.target.name === "energy_value"
            || e.target.name === "energy_value_kcal"
            || e.target.name === "fat"
            || e.target.name === "saturated_fat"
            || e.target.name === "carbohydrates"
            || e.target.name === "sugar"
            || e.target.name === "protein"
            || e.target.name === "salt") {
            setFormDataEng({ ...formDataEng, [e.target.name]: e.target.value });
            setFormDataSk({ ...formDataSk, [e.target.name]: e.target.value });
            setFormDataSv({ ...formDataSv, [e.target.name]: e.target.value });
            setFormDataCs({ ...formDataCs, [e.target.name]: e.target.value });
        }
    }

    const onChangeEng = e => {
        setFormDataEng({ ...formDataEng, [e.target.name]: e.target.value });

        if (e.target.name === "energy_value"
            || e.target.name === "energy_value_kcal"
            || e.target.name === "fat"
            || e.target.name === "saturated_fat"
            || e.target.name === "carbohydrates"
            || e.target.name === "sugar"
            || e.target.name === "protein"
            || e.target.name === "salt") {
            setFormData({ ...formData, [e.target.name]: e.target.value });
            setFormDataSk({ ...formDataSk, [e.target.name]: e.target.value });
            setFormDataSv({ ...formDataSv, [e.target.name]: e.target.value });
            setFormDataCs({ ...formDataCs, [e.target.name]: e.target.value });
        }
    };

    const onChangeSk = e => {
        setFormDataSk({ ...formDataSk, [e.target.name]: e.target.value });

        if (e.target.name === "energy_value"
            || e.target.name === "energy_value_kcal"
            || e.target.name === "fat"
            || e.target.name === "saturated_fat"
            || e.target.name === "carbohydrates"
            || e.target.name === "sugar"
            || e.target.name === "protein"
            || e.target.name === "salt") {
            setFormData({ ...formData, [e.target.name]: e.target.value });
            setFormDataEng({ ...formDataEng, [e.target.name]: e.target.value });
            setFormDataSv({ ...formDataSv, [e.target.name]: e.target.value });
            setFormDataCs({ ...formDataCs, [e.target.name]: e.target.value });
        }
    }


    const onChangeSv = e => {
        setFormDataSv({ ...formDataSv, [e.target.name]: e.target.value });

        if (e.target.name === "energy_value"
            || e.target.name === "energy_value_kcal"
            || e.target.name === "fat"
            || e.target.name === "saturated_fat"
            || e.target.name === "carbohydrates"
            || e.target.name === "sugar"
            || e.target.name === "protein"
            || e.target.name === "salt") {
            setFormData({ ...formData, [e.target.name]: e.target.value });
            setFormDataEng({ ...formDataEng, [e.target.name]: e.target.value });
            setFormDataSk({ ...formDataSk, [e.target.name]: e.target.value });
            setFormDataCs({ ...formDataCs, [e.target.name]: e.target.value });
        }
    }

    const onChangeCs = e => {
        console.log(e.target.name);
        setFormDataCs({ ...formDataCs, [e.target.name]: e.target.value });

        if (e.target.name === "energy_value"
            || e.target.name === "energy_value_kcal"
            || e.target.name === "fat"
            || e.target.name === "saturated_fat"
            || e.target.name === "carbohydrates"
            || e.target.name === "sugar"
            || e.target.name === "protein"
            || e.target.name === "salt") {
            setFormData({ ...formData, [e.target.name]: e.target.value });
            setFormDataEng({ ...formDataEng, [e.target.name]: e.target.value });
            setFormDataSk({ ...formDataSk, [e.target.name]: e.target.value });
            setFormDataSv({ ...formDataSv, [e.target.name]: e.target.value });
        }

    }

    const showWarning = () => {
        if (qr_label_list && qr_account) {
            const num = Number(qr_account.qr_count);
            const num2 = Number(qr_label_list.data.length);
            return num === num2;
        }
        return false;
    }

    const handleSave = () => {
        if (qr_label_list && qr_account) {
            const num = Number(qr_account.qr_count);
            const num2 = Number(qr_label_list.data.length);
            if (num === num2) return;
        }

        const payload = [formData, formDataEng, formDataSk, formDataSv, formDataCs];

        console.log("aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa");
        console.log(payload);
        console.log("aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa");
        action(false);

        dispatch(createBulkQrLabelCall(payload));
        setFormData({
            name: "",
            allergens: "",
            energy_value: "",
            energy_value_kcal: "",
            fat: "",
            saturated_fat: "",
            carbohydrates: "",
            sugar: "",
            protein: "",
            salt: "",
            category: "",
            alcoholic_strength: "",
            provenance: "",
            producer_info: "",
            net_content: "",
            sugar_content: "",
            durability_date: "",
            ingredients: "",
            nutritional_info: "",
            sulfites: "",
            lang: "hr",
            do_not_show: false
        });

        setFormDataEng({
            name: "",
            allergens: "",
            energy_value: "",
            energy_value_kcal: "",
            fat: "",
            saturated_fat: "",
            carbohydrates: "",
            sugar: "",
            protein: "",
            salt: "",
            category: "",
            alcoholic_strength: "",
            provenance: "",
            producer_info: "",
            net_content: "",
            sugar_content: "",
            durability_date: "",
            ingredients: "",
            nutritional_info: "",
            sulfites: "",
            lang: "en",
            do_not_show: false
        });

        setFormDataSk({
            name: "",
            allergens: "",
            energy_value: "",
            energy_value_kcal: "",
            fat: "",
            saturated_fat: "",
            carbohydrates: "",
            sugar: "",
            protein: "",
            salt: "",
            category: "",
            alcoholic_strength: "",
            provenance: "",
            producer_info: "",
            net_content: "",
            sugar_content: "",
            durability_date: "",
            ingredients: "",
            nutritional_info: "",
            sulfites: "",
            lang: "sk",
            do_not_show: false
        });

        setFormDataSv({
            name: "",
            allergens: "",
            energy_value: "",
            energy_value_kcal: "",
            fat: "",
            saturated_fat: "",
            carbohydrates: "",
            sugar: "",
            protein: "",
            salt: "",
            category: "",
            alcoholic_strength: "",
            provenance: "",
            producer_info: "",
            net_content: "",
            sugar_content: "",
            durability_date: "",
            ingredients: "",
            nutritional_info: "",
            sulfites: "",
            lang: "sv",
            do_not_show: false
        });

        setFormDataCs({
            name: "",
            allergens: "",
            energy_value: "",
            energy_value_kcal: "",
            fat: "",
            saturated_fat: "",
            carbohydrates: "",
            sugar: "",
            protein: "",
            salt: "",
            category: "",
            alcoholic_strength: "",
            provenance: "",
            producer_info: "",
            net_content: "",
            sugar_content: "",
            durability_date: "",
            ingredients: "",
            nutritional_info: "",
            sulfites: "",
            lang: "cs",
            do_not_show: false
        });
    };

    const changeLanguageHc = (lng) => {
        setLan(lng);
    };

    const showProperInputs = (lng) => {

        if (lng === "en") return show_En_Input();
        else if (lng === "sk") return show_Sk_Input();
        else if (lng === "hr") return show_Hr_Input();
        else if (lng === "sv") return show_Sv_Input();
        else if (lng === "cs") return show_Cs_Input();
        return show_En_Input();
    };


    const show_Cs_Input = () => {
        return (<>
            <form>
                <div className="row mb-3">

                    <div style={{ textAlign: 'left', display: 'flex', alignItems: 'center' }}> {/* Use 'center' for vertical alignment */}
                        <label className="side_pull_checkbox" style={{ display: 'flex', alignItems: 'center' }}>
                            <div className="checkbox">
                                <input
                                    id="do_not_show"
                                    name="do_not_show"
                                    type="checkbox"
                                    checked={formDataCs.do_not_show}

                                    onChange={onCheckboxChangeCs}
                                />
                                {/* Conditionally style the label text based on the checkbox state */}
                                <label
                                    className="tgl-btn"
                                    htmlFor="do_not_show"
                                    style={{
                                        marginLeft: "10px",
                                    }}
                                >
                                    {t('not_in_use_all_caps')}
                                </label>
                            </div>
                        </label>
                    </div>
                    <div className="col-12" >

                        <div className="form-group">
                            <label htmlFor="name">{t_cs('product_name_uppercase')}</label>
                            <input
                                type="text"
                                className="form-control"
                                id="name"
                                name="name"
                                onChange={onChangeCs}
                                value={formDataCs.name}
                                required
                            />
                        </div>
                    </div>
                </div>

                <div className="row mb-3">
                    <div className="col-md-8" style={{ border: "1px solid #ccc", borderRadius: "10px" }}>
                        <div className="form-group">
                            <label htmlFor="ingredients">{t_cs('composition_uppercase')}</label>
                            <textarea
                                className="form-control"
                                id="ingredients"
                                name="ingredients"
                                onChange={onChangeCs}
                                value={formDataCs.ingredients}
                                rows="5"
                            />
                        </div>
                    </div>
                    <div className="col-md-4" style={{ border: "1px solid #ccc", borderRadius: "10px" }}>
                        <div className="form-group">
                            <label>{t_cs('details_uppercase')}</label>
                            <input
                                type="text"
                                className="form-control mb-2"
                                id="provenance"
                                name="provenance"
                                onChange={onChangeCs}
                                value={formDataCs.provenance}
                                placeholder={t_cs('name')}
                            />
                            <input
                                type="text"
                                className="form-control mb-2"
                                id="category"
                                name="category"
                                onChange={onChangeCs}
                                value={formDataCs.category}
                                placeholder={t_cs('category')}
                            />
                            <input
                                type="text"
                                className="form-control"
                                id="alcoholic_strength"
                                name="alcoholic_strength"
                                onChange={onChangeCs}
                                value={formDataCs.alcoholic_strength}
                                placeholder={t_cs('origin')}
                            />
                        </div>
                    </div>
                </div>

                <div className="row mb-3">
                    <div className="col-md-8" style={{ border: "1px solid #ccc", borderRadius: "10px" }}>
                        <div className="form-group">
                            <label htmlFor="energy_value">{t_cs('PHV_UPPERCASE')}</label>
                            <div className="row">
                                <div className="col-6">
                                    <label style={{ fontSize: '13px' }} htmlFor="energy_value">{t_cs('energy_value_kj')}</label>
                                    <input
                                        type="text"
                                        className="form-control mb-2"
                                        id="energy_value"
                                        name="energy_value"
                                        onChange={onChangeCs}
                                        value={formDataCs.energy_value}
                                    />
                                </div>
                                <div className="col-6">
                                    <label style={{ fontSize: '13px' }} htmlFor="energy_value_kcal">{t_cs('energy_value_kcal')}</label>
                                    <input
                                        type="text"
                                        className="form-control mb-2"
                                        id="energy_value_kcal"
                                        name="energy_value_kcal"
                                        onChange={onChangeCs}
                                        value={formDataCs.energy_value_kcal}
                                    />
                                </div>
                                <div className="col-6">
                                    <label style={{ fontSize: '13px' }} htmlFor="fat">{t_cs('fat')}</label>
                                    <input
                                        type="text"
                                        className="form-control mb-2"
                                        id="fat"
                                        name="fat"
                                        onChange={onChangeCs}
                                        value={formDataCs.fat}
                                    />
                                </div>
                                <div className="col-6">
                                    <label style={{ fontSize: '13px' }} htmlFor="saturated_fat">{t_cs('saturated_fat')}</label>
                                    <input
                                        type="text"
                                        className="form-control mb-2"
                                        id="saturated_fat"
                                        name="saturated_fat"
                                        onChange={onChangeCs}
                                        value={formDataCs.saturated_fat}
                                    />
                                </div>
                                <div className="col-6">
                                    <label style={{ fontSize: '13px' }} htmlFor="carbohydrates">{t_cs('carbohydrates')}</label>
                                    <input
                                        type="text"
                                        className="form-control mb-2"
                                        id="carbohydrates"
                                        name="carbohydrates"
                                        onChange={onChangeCs}
                                        value={formDataCs.carbohydrates}
                                    />
                                </div>
                                <div className="col-6">
                                    <label style={{ fontSize: '13px' }} htmlFor="sugar">{t_cs('sugars')}</label>
                                    <input
                                        type="text"
                                        className="form-control mb-2"
                                        id="sugar"
                                        name="sugar"
                                        onChange={onChangeCs}
                                        value={formDataCs.sugar}
                                    />
                                </div>
                                <div className="col-6">
                                    <label style={{ fontSize: '13px' }} htmlFor="protein">{t_cs('protein')}</label>
                                    <input
                                        type="text"
                                        className="form-control mb-2"
                                        id="protein"
                                        name="protein"
                                        onChange={onChangeCs}
                                        value={formDataCs.protein}
                                    />
                                </div>
                                <div className="col-6">
                                    <label style={{ fontSize: '13px' }} htmlFor="salt">{t_cs('salt')}</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="salt"
                                        name="salt"
                                        onChange={onChangeCs}
                                        value={formDataCs.salt}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4" style={{ border: "1px solid #ccc", borderRadius: "10px" }}>
                        <div className="form-group">
                            <label>{t_cs('content_uppercase')}</label>
                            <input
                                type="text"
                                className="form-control mb-2"
                                id="allergens"
                                name="allergens"
                                onChange={onChangeCs}
                                value={formDataCs.allergens}
                                placeholder={t_cs('allergens')}
                                required
                            />
                            <input
                                type="text"
                                className="form-control"
                                id="sulfites"
                                name="sulfites"
                                onChange={onChangeCs}
                                value={formDataCs.sulfites}
                                placeholder={t_cs('sulfites')}
                            />
                        </div>
                    </div>
                </div>

                <div className="form-group">
                    <label htmlFor="name">{t_cs('manufacturer')}</label>
                    <input
                        type="text"
                        className="form-control"
                        id="company_name"
                        name="company_name"
                        onChange={onChangeCs}
                        value={formDataCs.company_name}
                        required
                    />
                </div>

                <div className="form-group">
                    <label htmlFor="name">{t_cs('address')}</label>
                    <input
                        type="text"
                        className="form-control"
                        id="company_address"
                        name="company_address"
                        onChange={onChangeCs}
                        value={formDataCs.company_address}
                        required
                    />
                </div>

                <div className="form-group">
                    <label htmlFor="name">{t_cs('oib')}</label>
                    <input
                        type="text"
                        className="form-control"
                        id="company_oib"
                        name="company_oib"
                        onChange={onChangeCs}
                        value={formDataCs.company_oib}
                        required
                    />
                </div>

                <div className="form-group">
                    <label htmlFor="name">{t_cs('phone')}</label>
                    <input
                        type="text"
                        className="form-control"
                        id="company_phone"
                        name="company_phone"
                        onChange={onChangeCs}
                        value={formDataCs.company_phone}
                        required
                    />
                </div>

                <div className="form-group">
                    <label htmlFor="name">{t_cs('email')}</label>
                    <input
                        type="text"
                        className="form-control"
                        id="company_email"
                        name="company_email"
                        onChange={onChangeCs}
                        value={formDataCs.company_email}
                        required
                    />
                </div>

                <button type="button" className="btn btn-primary mt-4" onClick={handleSave}>{t_cs('save')}</button>
            </form></>);
    };

    const show_Sv_Input = () => {
        return (<><form>
            <div className="row mb-3">

                <div style={{ textAlign: 'left', display: 'flex', alignItems: 'center' }}> {/* Use 'center' for vertical alignment */}
                    <label className="side_pull_checkbox" style={{ display: 'flex', alignItems: 'center' }}>
                        <div className="checkbox">
                            <input
                                id="do_not_show"
                                name="do_not_show"
                                type="checkbox"
                                checked={formDataSv.do_not_show}

                                onChange={onCheckboxChangeSv}
                            />
                            {/* Conditionally style the label text based on the checkbox state */}
                            <label
                                className="tgl-btn"
                                htmlFor="do_not_show"
                                style={{
                                    marginLeft: "10px",
                                }}
                            >
                                {t('not_in_use_all_caps')}
                            </label>
                        </div>
                    </label>
                </div>
                <div className="col-12" >

                    <div className="form-group">
                        <label htmlFor="name">{t_sv('product_name_uppercase')}</label>
                        <input
                            type="text"
                            className="form-control"
                            id="name"
                            name="name"
                            onChange={onChangeSv}
                            value={formDataSv.name}
                            required
                        />
                    </div>
                </div>
            </div>

            <div className="row mb-3">
                <div className="col-md-8" style={{ border: "1px solid #ccc", borderRadius: "10px" }}>
                    <div className="form-group">
                        <label htmlFor="ingredients">{t_sv('composition_uppercase')}</label>
                        <textarea
                            className="form-control"
                            id="ingredients"
                            name="ingredients"
                            onChange={onChangeSv}
                            value={formDataSv.ingredients}
                            rows="5"
                        />
                    </div>
                </div>
                <div className="col-md-4" style={{ border: "1px solid #ccc", borderRadius: "10px" }}>
                    <div className="form-group">
                        <label>{t_sv('details_uppercase')}</label>
                        <input
                            type="text"
                            className="form-control mb-2"
                            id="provenance"
                            name="provenance"
                            onChange={onChangeSv}
                            value={formDataSv.provenance}
                            placeholder={t_sv('name')}
                        />
                        <input
                            type="text"
                            className="form-control mb-2"
                            id="category"
                            name="category"
                            onChange={onChangeSv}
                            value={formDataSv.category}
                            placeholder={t_sv('category')}
                        />
                        <input
                            type="text"
                            className="form-control"
                            id="alcoholic_strength"
                            name="alcoholic_strength"
                            onChange={onChangeSv}
                            value={formDataSv.alcoholic_strength}
                            placeholder={t_sv('origin')}
                        />
                    </div>
                </div>
            </div>

            <div className="row mb-3">
                <div className="col-md-8" style={{ border: "1px solid #ccc", borderRadius: "10px" }}>
                    <div className="form-group">
                        <label htmlFor="energy_value">{t_sv('PHV_UPPERCASE')}</label>
                        <div className="row">
                            <div className="col-6">
                                <label style={{ fontSize: '13px' }} htmlFor="energy_value">{t_sv('energy_value_kj')}</label>
                                <input
                                    type="text"
                                    className="form-control mb-2"
                                    id="energy_value"
                                    name="energy_value"
                                    onChange={onChangeSv}
                                    value={formDataSv.energy_value}
                                />
                            </div>
                            <div className="col-6">
                                <label style={{ fontSize: '13px' }} htmlFor="energy_value_kcal">{t_sv('energy_value_kcal')}</label>
                                <input
                                    type="text"
                                    className="form-control mb-2"
                                    id="energy_value_kcal"
                                    name="energy_value_kcal"
                                    onChange={onChangeSv}
                                    value={formDataSv.energy_value_kcal}
                                />
                            </div>
                            <div className="col-6">
                                <label style={{ fontSize: '13px' }} htmlFor="fat">{t_sv('fat')}</label>
                                <input
                                    type="text"
                                    className="form-control mb-2"
                                    id="fat"
                                    name="fat"
                                    onChange={onChangeSv}
                                    value={formDataSv.fat}
                                />
                            </div>
                            <div className="col-6">
                                <label style={{ fontSize: '13px' }} htmlFor="saturated_fat">{t_sv('saturated_fat')}</label>
                                <input
                                    type="text"
                                    className="form-control mb-2"
                                    id="saturated_fat"
                                    name="saturated_fat"
                                    onChange={onChangeSv}
                                    value={formDataSv.saturated_fat}
                                />
                            </div>
                            <div className="col-6">
                                <label style={{ fontSize: '13px' }} htmlFor="carbohydrates">{t_sv('carbohydrates')}</label>
                                <input
                                    type="text"
                                    className="form-control mb-2"
                                    id="carbohydrates"
                                    name="carbohydrates"
                                    onChange={onChangeSv}
                                    value={formDataSv.carbohydrates}
                                />
                            </div>
                            <div className="col-6">
                                <label style={{ fontSize: '13px' }} htmlFor="sugar">{t_sv('sugars')}</label>
                                <input
                                    type="text"
                                    className="form-control mb-2"
                                    id="sugar"
                                    name="sugar"
                                    onChange={onChangeSv}
                                    value={formDataSv.sugar}
                                />
                            </div>
                            <div className="col-6">
                                <label style={{ fontSize: '13px' }} htmlFor="protein">{t_sv('protein')}</label>
                                <input
                                    type="text"
                                    className="form-control mb-2"
                                    id="protein"
                                    name="protein"
                                    onChange={onChangeSv}
                                    value={formDataSv.protein}
                                />
                            </div>
                            <div className="col-6">
                                <label style={{ fontSize: '13px' }} htmlFor="salt">{t_sv('salt')}</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="salt"
                                    name="salt"
                                    onChange={onChangeSv}
                                    value={formDataSv.salt}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-4" style={{ border: "1px solid #ccc", borderRadius: "10px" }}>
                    <div className="form-group">
                        <label>{t_sv('content_uppercase')}</label>
                        <input
                            type="text"
                            className="form-control mb-2"
                            id="allergens"
                            name="allergens"
                            onChange={onChangeSv}
                            value={formDataSv.allergens}
                            placeholder={t_sv('allergens')}
                            required
                        />
                        <input
                            type="text"
                            className="form-control"
                            id="sulfites"
                            name="sulfites"
                            onChange={onChangeSv}
                            value={formDataSv.sulfites}
                            placeholder={t_sv('sulfites')}
                        />
                    </div>
                </div>
            </div>

            <div className="form-group">
                <label htmlFor="name">{t_sv('manufacturer')}</label>
                <input
                    type="text"
                    className="form-control"
                    id="company_name"
                    name="company_name"
                    onChange={onChangeSv}
                    value={formDataSv.company_name}
                    required
                />
            </div>

            <div className="form-group">
                <label htmlFor="name">{t_sv('address')}</label>
                <input
                    type="text"
                    className="form-control"
                    id="company_address"
                    name="company_address"
                    onChange={onChangeSv}
                    value={formDataSv.company_address}
                    required
                />
            </div>

            <div className="form-group">
                <label htmlFor="name">{t_sv('oib')}</label>
                <input
                    type="text"
                    className="form-control"
                    id="company_oib"
                    name="company_oib"
                    onChange={onChangeSv}
                    value={formDataSv.company_oib}
                    required
                />
            </div>

            <div className="form-group">
                <label htmlFor="name">{t_sv('phone')}</label>
                <input
                    type="text"
                    className="form-control"
                    id="company_phone"
                    name="company_phone"
                    onChange={onChangeSv}
                    value={formDataSv.company_phone}
                    required
                />
            </div>

            <div className="form-group">
                <label htmlFor="name">{t_sv('email')}</label>
                <input
                    type="text"
                    className="form-control"
                    id="company_email"
                    name="company_email"
                    onChange={onChangeSv}
                    value={formDataSv.company_email}
                    required
                />
            </div>

            <button type="button" className="btn btn-primary mt-4" onClick={handleSave}>{t_sv('save')}</button>
        </form></>);
    };

    const show_Sk_Input = () => {
        return (<><form>
            <div className="row mb-3">

                <div style={{ textAlign: 'left', display: 'flex', alignItems: 'center' }}> {/* Use 'center' for vertical alignment */}
                    <label className="side_pull_checkbox" style={{ display: 'flex', alignItems: 'center' }}>
                        <div className="checkbox">
                            <input
                                id="do_not_show"
                                name="do_not_show"
                                type="checkbox"
                                checked={formDataSk.do_not_show}

                                onChange={onCheckboxChangeSk}
                            />
                            {/* Conditionally style the label text based on the checkbox state */}
                            <label
                                className="tgl-btn"
                                htmlFor="do_not_show"
                                style={{
                                    marginLeft: "10px",
                                }}
                            >
                                {t('not_in_use_all_caps')}
                            </label>
                        </div>
                    </label>
                </div>
                <div className="col-12" >

                    <div className="form-group">
                        <label htmlFor="name">{t_sk('product_name_uppercase')}</label>
                        <input
                            type="text"
                            className="form-control"
                            id="name"
                            name="name"
                            onChange={onChangeSk}
                            value={formDataSk.name}
                            required
                        />
                    </div>
                </div>
            </div>

            <div className="row mb-3">
                <div className="col-md-8" style={{ border: "1px solid #ccc", borderRadius: "10px" }}>
                    <div className="form-group">
                        <label htmlFor="ingredients">{t_sk('composition_uppercase')}</label>
                        <textarea
                            className="form-control"
                            id="ingredients"
                            name="ingredients"
                            onChange={onChangeSk}
                            value={formDataSk.ingredients}
                            rows="5"
                        />
                    </div>
                </div>
                <div className="col-md-4" style={{ border: "1px solid #ccc", borderRadius: "10px" }}>
                    <div className="form-group">
                        <label>{t_sk('details_uppercase')}</label>
                        <input
                            type="text"
                            className="form-control mb-2"
                            id="provenance"
                            name="provenance"
                            onChange={onChangeSk}
                            value={formDataSk.provenance}
                            placeholder={t_sk('name')}
                        />
                        <input
                            type="text"
                            className="form-control mb-2"
                            id="category"
                            name="category"
                            onChange={onChangeSk}
                            value={formDataSk.category}
                            placeholder={t_sk('category')}
                        />
                        <input
                            type="text"
                            className="form-control"
                            id="alcoholic_strength"
                            name="alcoholic_strength"
                            onChange={onChangeSk}
                            value={formDataSk.alcoholic_strength}
                            placeholder={t_sk('origin')}
                        />
                    </div>
                </div>
            </div>

            <div className="row mb-3">
                <div className="col-md-8" style={{ border: "1px solid #ccc", borderRadius: "10px" }}>
                    <div className="form-group">
                        <label htmlFor="energy_value">{t_sk('PHV_UPPERCASE')}</label>
                        <div className="row">
                            <div className="col-6">
                                <label style={{ fontSize: '13px' }} htmlFor="energy_value">{t_sk('energy_value_kj')}</label>
                                <input
                                    type="text"
                                    className="form-control mb-2"
                                    id="energy_value"
                                    name="energy_value"
                                    onChange={onChangeSk}
                                    value={formDataSk.energy_value}
                                />
                            </div>
                            <div className="col-6">
                                <label style={{ fontSize: '13px' }} htmlFor="energy_value_kcal">{t_sk('energy_value_kcal')}</label>
                                <input
                                    type="text"
                                    className="form-control mb-2"
                                    id="energy_value_kcal"
                                    name="energy_value_kcal"
                                    onChange={onChangeSk}
                                    value={formDataSk.energy_value_kcal}
                                />
                            </div>
                            <div className="col-6">
                                <label style={{ fontSize: '13px' }} htmlFor="fat">{t_sk('fat')}</label>
                                <input
                                    type="text"
                                    className="form-control mb-2"
                                    id="fat"
                                    name="fat"
                                    onChange={onChangeSk}
                                    value={formDataSk.fat}
                                />
                            </div>
                            <div className="col-6">
                                <label style={{ fontSize: '13px' }} htmlFor="saturated_fat">{t_sk('saturated_fat')}</label>
                                <input
                                    type="text"
                                    className="form-control mb-2"
                                    id="saturated_fat"
                                    name="saturated_fat"
                                    onChange={onChangeSk}
                                    value={formDataSk.saturated_fat}
                                />
                            </div>
                            <div className="col-6">
                                <label style={{ fontSize: '13px' }} htmlFor="carbohydrates">{t_sk('carbohydrates')}</label>
                                <input
                                    type="text"
                                    className="form-control mb-2"
                                    id="carbohydrates"
                                    name="carbohydrates"
                                    onChange={onChangeSk}
                                    value={formDataSk.carbohydrates}
                                />
                            </div>
                            <div className="col-6">
                                <label style={{ fontSize: '13px' }} htmlFor="sugar">{t_sk('sugars')}</label>
                                <input
                                    type="text"
                                    className="form-control mb-2"
                                    id="sugar"
                                    name="sugar"
                                    onChange={onChangeSk}
                                    value={formDataSk.sugar}
                                />
                            </div>
                            <div className="col-6">
                                <label style={{ fontSize: '13px' }} htmlFor="protein">{t_sk('protein')}</label>
                                <input
                                    type="text"
                                    className="form-control mb-2"
                                    id="protein"
                                    name="protein"
                                    onChange={onChangeSk}
                                    value={formDataSk.protein}
                                />
                            </div>
                            <div className="col-6">
                                <label style={{ fontSize: '13px' }} htmlFor="salt">{t_sk('salt')}</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="salt"
                                    name="salt"
                                    onChange={onChangeSk}
                                    value={formDataSk.salt}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-4" style={{ border: "1px solid #ccc", borderRadius: "10px" }}>
                    <div className="form-group">
                        <label>{t_sk('content_uppercase')}</label>
                        <input
                            type="text"
                            className="form-control mb-2"
                            id="allergens"
                            name="allergens"
                            onChange={onChangeSk}
                            value={formDataSk.allergens}
                            placeholder={t_sk('allergens')}
                            required
                        />
                        <input
                            type="text"
                            className="form-control"
                            id="sulfites"
                            name="sulfites"
                            onChange={onChangeSk}
                            value={formDataSk.sulfites}
                            placeholder={t_sk('sulfites')}
                        />
                    </div>
                </div>
            </div>

            <div className="form-group">
                <label htmlFor="name">{t_sk('manufacturer')}</label>
                <input
                    type="text"
                    className="form-control"
                    id="company_name"
                    name="company_name"
                    onChange={onChangeSk}
                    value={formDataSk.company_name}
                    required
                />
            </div>

            <div className="form-group">
                <label htmlFor="name">{t_sk('address')}</label>
                <input
                    type="text"
                    className="form-control"
                    id="company_address"
                    name="company_address"
                    onChange={onChangeSk}
                    value={formDataSk.company_address}
                    required
                />
            </div>

            <div className="form-group">
                <label htmlFor="name">{t_sk('oib')}</label>
                <input
                    type="text"
                    className="form-control"
                    id="company_oib"
                    name="company_oib"
                    onChange={onChangeSk}
                    value={formDataSk.company_oib}
                    required
                />
            </div>

            <div className="form-group">
                <label htmlFor="name">{t_sk('phone')}</label>
                <input
                    type="text"
                    className="form-control"
                    id="company_phone"
                    name="company_phone"
                    onChange={onChangeSk}
                    value={formDataSk.company_phone}
                    required
                />
            </div>

            <div className="form-group">
                <label htmlFor="name">{t_sk('email')}</label>
                <input
                    type="text"
                    className="form-control"
                    id="company_email"
                    name="company_email"
                    onChange={onChangeSk}
                    value={formDataSk.company_email}
                    required
                />
            </div>

            <button type="button" className="btn btn-primary mt-4" onClick={handleSave}>{t_sk('save')}</button>
        </form></>);
    };

    const show_En_Input = () => {
        return (<><form>
            <div className="row mb-3">
                <div style={{ textAlign: 'left', display: 'flex', alignItems: 'center' }}> {/* Use 'center' for vertical alignment */}
                    <label className="side_pull_checkbox" style={{ display: 'flex', alignItems: 'center' }}>
                        <div className="checkbox">
                            <input
                                id="do_not_show"
                                name="do_not_show"
                                type="checkbox"
                                checked={formDataEng.do_not_show}

                                onChange={onCheckboxChangeEn}
                            />
                            {/* Conditionally style the label text based on the checkbox state */}
                            <label
                                className="tgl-btn"
                                htmlFor="do_not_show"
                                style={{
                                    marginLeft: "10px",
                                }}
                            >
                                {t('not_in_use_all_caps')}
                            </label>
                        </div>
                    </label>
                </div>
                <div className="col-12" >

                    <div className="form-group">
                        <label htmlFor="name">{t_eng('product_name_uppercase')}</label>
                        <input
                            type="text"
                            className="form-control"
                            id="name"
                            name="name"
                            onChange={onChangeEng}
                            value={formDataEng.name}
                            required
                        />
                    </div>
                </div>
            </div>

            <div className="row mb-3">
                <div className="col-md-8" style={{ border: "1px solid #ccc", borderRadius: "10px" }}>
                    <div className="form-group">
                        <label htmlFor="ingredients">{t_eng('composition_uppercase')}</label>
                        <textarea
                            className="form-control"
                            id="ingredients"
                            name="ingredients"
                            onChange={onChangeEng}
                            value={formDataEng.ingredients}
                            rows="5"
                        />
                    </div>
                </div>
                <div className="col-md-4" style={{ border: "1px solid #ccc", borderRadius: "10px" }}>
                    <div className="form-group">
                        <label>{t_eng('details_uppercase')}</label>
                        <input
                            type="text"
                            className="form-control mb-2"
                            id="provenance"
                            name="provenance"
                            onChange={onChangeEng}
                            value={formDataEng.provenance}
                            placeholder={t_eng('name')}
                        />
                        <input
                            type="text"
                            className="form-control mb-2"
                            id="category"
                            name="category"
                            onChange={onChangeEng}
                            value={formDataEng.category}
                            placeholder={t_eng('category')}
                        />
                        <input
                            type="text"
                            className="form-control"
                            id="alcoholic_strength"
                            name="alcoholic_strength"
                            onChange={onChangeEng}
                            value={formDataEng.alcoholic_strength}
                            placeholder={t_eng('origin')}
                        />
                    </div>
                </div>
            </div>

            <div className="row mb-3">
                <div className="col-md-8" style={{ border: "1px solid #ccc", borderRadius: "10px" }}>
                    <div className="form-group">
                        <label htmlFor="energy_value">{t_eng('PHV_UPPERCASE')}</label>
                        <div className="row">
                            <div className="col-6">
                                <label style={{ fontSize: '13px' }} htmlFor="energy_value">{t_eng('energy_value_kj')}</label>
                                <input
                                    type="text"
                                    className="form-control mb-2"
                                    id="energy_value"
                                    name="energy_value"
                                    onChange={onChangeEng}
                                    value={formDataEng.energy_value}
                                />
                            </div>
                            <div className="col-6">
                                <label style={{ fontSize: '13px' }} htmlFor="energy_value_kcal">{t_eng('energy_value_kcal')}</label>
                                <input
                                    type="text"
                                    className="form-control mb-2"
                                    id="energy_value_kcal"
                                    name="energy_value_kcal"
                                    onChange={onChangeEng}
                                    value={formDataEng.energy_value_kcal}
                                />
                            </div>
                            <div className="col-6">
                                <label style={{ fontSize: '13px' }} htmlFor="fat">{t_eng('fat')}</label>
                                <input
                                    type="text"
                                    className="form-control mb-2"
                                    id="fat"
                                    name="fat"
                                    onChange={onChangeEng}
                                    value={formDataEng.fat}
                                />
                            </div>
                            <div className="col-6">
                                <label style={{ fontSize: '13px' }} htmlFor="saturated_fat">{t_eng('saturated_fat')}</label>
                                <input
                                    type="text"
                                    className="form-control mb-2"
                                    id="saturated_fat"
                                    name="saturated_fat"
                                    onChange={onChangeEng}
                                    value={formDataEng.saturated_fat}
                                />
                            </div>
                            <div className="col-6">
                                <label style={{ fontSize: '13px' }} htmlFor="carbohydrates">{t_eng('carbohydrates')}</label>
                                <input
                                    type="text"
                                    className="form-control mb-2"
                                    id="carbohydrates"
                                    name="carbohydrates"
                                    onChange={onChangeEng}
                                    value={formDataEng.carbohydrates}
                                />
                            </div>
                            <div className="col-6">
                                <label style={{ fontSize: '13px' }} htmlFor="sugar">{t_eng('sugars')}</label>
                                <input
                                    type="text"
                                    className="form-control mb-2"
                                    id="sugar"
                                    name="sugar"
                                    onChange={onChangeEng}
                                    value={formDataEng.sugar}
                                />
                            </div>
                            <div className="col-6">
                                <label style={{ fontSize: '13px' }} htmlFor="protein">{t_eng('protein')}</label>
                                <input
                                    type="text"
                                    className="form-control mb-2"
                                    id="protein"
                                    name="protein"
                                    onChange={onChangeEng}
                                    value={formDataEng.protein}
                                />
                            </div>
                            <div className="col-6">
                                <label style={{ fontSize: '13px' }} htmlFor="salt">{t_eng('salt')}</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="salt"
                                    name="salt"
                                    onChange={onChangeEng}
                                    value={formDataEng.salt}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-4" style={{ border: "1px solid #ccc", borderRadius: "10px" }}>
                    <div className="form-group">
                        <label>{t_eng('content_uppercase')}</label>
                        <input
                            type="text"
                            className="form-control mb-2"
                            id="allergens"
                            name="allergens"
                            onChange={onChangeEng}
                            value={formDataEng.allergens}
                            placeholder={t_eng('allergens')}
                            required
                        />
                        <input
                            type="text"
                            className="form-control"
                            id="sulfites"
                            name="sulfites"
                            onChange={onChangeEng}
                            value={formDataEng.sulfites}
                            placeholder={t_eng('sulfites')}
                        />
                    </div>
                </div>
            </div>

            <div className="form-group">
                <label htmlFor="name">{t_eng('manufacturer')}</label>
                <input
                    type="text"
                    className="form-control"
                    id="company_name"
                    name="company_name"
                    onChange={onChangeEng}
                    value={formDataEng.company_name}
                    required
                />
            </div>

            <div className="form-group">
                <label htmlFor="name">{t_eng('address')}</label>
                <input
                    type="text"
                    className="form-control"
                    id="company_address"
                    name="company_address"
                    onChange={onChangeEng}
                    value={formDataEng.company_address}
                    required
                />
            </div>

            <div className="form-group">
                <label htmlFor="name">{t_eng('oib')}</label>
                <input
                    type="text"
                    className="form-control"
                    id="company_oib"
                    name="company_oib"
                    onChange={onChangeEng}
                    value={formDataEng.company_oib}
                    required
                />
            </div>

            <div className="form-group">
                <label htmlFor="name">{t_eng('phone')}</label>
                <input
                    type="text"
                    className="form-control"
                    id="company_phone"
                    name="company_phone"
                    onChange={onChangeEng}
                    value={formDataEng.company_phone}
                    required
                />
            </div>

            <div className="form-group">
                <label htmlFor="name">{t_eng('email')}</label>
                <input
                    type="text"
                    className="form-control"
                    id="company_email"
                    name="company_email"
                    onChange={onChangeEng}
                    value={formDataEng.company_email}
                    required
                />
            </div>

            <button type="button" className="btn btn-primary mt-4" onClick={handleSave}>{t_eng('save')}</button>
        </form></>);
    };

    const show_Hr_Input = () => {
        return (<><form>
            <div className="row mb-3">

                <div style={{ textAlign: 'left', display: 'flex', alignItems: 'center' }}> {/* Use 'center' for vertical alignment */}
                    <label className="side_pull_checkbox" style={{ display: 'flex', alignItems: 'center' }}>
                        <div className="checkbox">
                            <input
                                id="do_not_show"
                                name="do_not_show"
                                type="checkbox"
                                checked={formData.do_not_show}

                                onChange={onCheckboxChange}
                            />
                            {/* Conditionally style the label text based on the checkbox state */}
                            <label
                                className="tgl-btn"
                                htmlFor="do_not_show"
                                style={{
                                    marginLeft: "10px",
                                }}
                            >
                                {t('not_in_use_all_caps')}
                            </label>
                        </div>
                    </label>
                </div>


                <div className="form-group">
                    <label htmlFor="name">{t_hr('product_name_uppercase')}</label>
                    <input
                        type="text"
                        className="form-control"
                        id="name"
                        name="name"
                        onChange={onChange}
                        value={formData.name}
                        required
                    />
                </div>

            </div>

            <div className="row mb-3">
                <div className="col-md-8" style={{ border: "1px solid #ccc", borderRadius: "10px" }}>
                    <div className="form-group">
                        <label htmlFor="ingredients">{t_hr('composition_uppercase')}</label>
                        <textarea
                            className="form-control"
                            id="ingredients"
                            name="ingredients"
                            onChange={onChange}
                            value={formData.ingredients}
                            rows="5"
                        />
                    </div>
                </div>
                <div className="col-md-4" style={{ border: "1px solid #ccc", borderRadius: "10px" }}>
                    <div className="form-group">
                        <label>{t_hr('details_uppercase')}</label>
                        <input
                            type="text"
                            className="form-control mb-2"
                            id="provenance"
                            name="provenance"
                            onChange={onChange}
                            value={formData.provenance}
                            placeholder={t_hr('name')}
                        />
                        <input
                            type="text"
                            className="form-control mb-2"
                            id="category"
                            name="category"
                            onChange={onChange}
                            value={formData.category}
                            placeholder={t_hr('category')}
                        />
                        <input
                            type="text"
                            className="form-control"
                            id="alcoholic_strength"
                            name="alcoholic_strength"
                            onChange={onChange}
                            value={formData.alcoholic_strength}
                            placeholder={t_hr('origin')}
                        />
                    </div>
                </div>
            </div>

            <div className="row mb-3">
                <div className="col-md-8" style={{ border: "1px solid #ccc", borderRadius: "10px" }}>
                    <div className="form-group">
                        <label htmlFor="energy_value">{t_hr('PHV_UPPERCASE')}</label>
                        <div className="row">
                            <div className="col-6">
                                <label style={{ fontSize: '13px' }} htmlFor="energy_value">{t_hr('energy_value_kj')}</label>
                                <input
                                    type="text"
                                    className="form-control mb-2"
                                    id="energy_value"
                                    name="energy_value"
                                    onChange={onChange}
                                    value={formData.energy_value}
                                />
                            </div>
                            <div className="col-6">
                                <label style={{ fontSize: '13px' }} htmlFor="energy_value_kcal">{t_hr('energy_value_kcal')}</label>
                                <input
                                    type="text"
                                    className="form-control mb-2"
                                    id="energy_value_kcal"
                                    name="energy_value_kcal"
                                    onChange={onChange}
                                    value={formData.energy_value_kcal}
                                />
                            </div>
                            <div className="col-6">
                                <label style={{ fontSize: '13px' }} htmlFor="fat">{t_hr('fat')}</label>
                                <input
                                    type="text"
                                    className="form-control mb-2"
                                    id="fat"
                                    name="fat"
                                    onChange={onChange}
                                    value={formData.fat}
                                />
                            </div>
                            <div className="col-6">
                                <label style={{ fontSize: '13px' }} htmlFor="saturated_fat">{t_hr('saturated_fat')}</label>
                                <input
                                    type="text"
                                    className="form-control mb-2"
                                    id="saturated_fat"
                                    name="saturated_fat"
                                    onChange={onChange}
                                    value={formData.saturated_fat}
                                />
                            </div>
                            <div className="col-6">
                                <label style={{ fontSize: '13px' }} htmlFor="carbohydrates">{t_hr('carbohydrates')}</label>
                                <input
                                    type="text"
                                    className="form-control mb-2"
                                    id="carbohydrates"
                                    name="carbohydrates"
                                    onChange={onChange}
                                    value={formData.carbohydrates}
                                />
                            </div>
                            <div className="col-6">
                                <label style={{ fontSize: '13px' }} htmlFor="sugar">{t_hr('sugars')}</label>
                                <input
                                    type="text"
                                    className="form-control mb-2"
                                    id="sugar"
                                    name="sugar"
                                    onChange={onChange}
                                    value={formData.sugar}
                                />
                            </div>
                            <div className="col-6">
                                <label style={{ fontSize: '13px' }} htmlFor="protein">{t_hr('protein')}</label>
                                <input
                                    type="text"
                                    className="form-control mb-2"
                                    id="protein"
                                    name="protein"
                                    onChange={onChange}
                                    value={formData.protein}
                                />
                            </div>
                            <div className="col-6">
                                <label style={{ fontSize: '13px' }} htmlFor="salt">{t_hr('salt')}</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="salt"
                                    name="salt"
                                    onChange={onChange}
                                    value={formData.salt}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-4" style={{ border: "1px solid #ccc", borderRadius: "10px" }}>
                    <div className="form-group">
                        <label>{t_hr('content_uppercase')}</label>
                        <input
                            type="text"
                            className="form-control mb-2"
                            id="allergens"
                            name="allergens"
                            onChange={onChange}
                            value={formData.allergens}
                            placeholder={t_hr('allergens')}
                            required
                        />
                        <input
                            type="text"
                            className="form-control"
                            id="sulfites"
                            name="sulfites"
                            onChange={onChange}
                            value={formData.sulfites}
                            placeholder={t_hr('sulfites')}
                        />
                    </div>
                </div>
            </div>

            <div className="form-group">
                <label htmlFor="name">{t_hr('manufacturer')}</label>
                <input
                    type="text"
                    className="form-control"
                    id="company_name"
                    name="company_name"
                    onChange={onChange}
                    value={formData.company_name}
                    required
                />
            </div>

            <div className="form-group">
                <label htmlFor="name">{t_hr('address')}</label>
                <input
                    type="text"
                    className="form-control"
                    id="company_address"
                    name="company_address"
                    onChange={onChange}
                    value={formData.company_address}
                    required
                />
            </div>

            <div className="form-group">
                <label htmlFor="name">{t_hr('oib')}</label>
                <input
                    type="text"
                    className="form-control"
                    id="company_oib"
                    name="company_oib"
                    onChange={onChange}
                    value={formData.company_oib}
                    required
                />
            </div>

            <div className="form-group">
                <label htmlFor="name">{t_hr('phone')}</label>
                <input
                    type="text"
                    className="form-control"
                    id="company_phone"
                    name="company_phone"
                    onChange={onChange}
                    value={formData.company_phone}
                    required
                />
            </div>

            <div className="form-group">
                <label htmlFor="name">{t_hr('email')}</label>
                <input
                    type="text"
                    className="form-control"
                    id="company_email"
                    name="company_email"
                    onChange={onChange}
                    value={formData.company_email}
                    required
                />
            </div>

            <button type="button" className="btn btn-primary mt-4" onClick={handleSave}>{t('save')}</button>
        </form></>);

    };

    return (
        <div className="container">
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>

                <div>
                    <h5>{t('add_new_product')}: </h5>
                </div>

                <div>
                    <div style={{ marginTop: "-15px", marginRight: "25px" }}>
                        <select name="switchbox" value={lan} onChange={(e) => changeLanguageHc(e.target.value)}>
                            <option value="hr">Hr</option>
                            <option value="en">En</option>
                            <option value="sk">Sk</option>
                            <option value="sv">Sv</option>
                            <option value="cs">Cz</option>
                            {/* Add more languages here */}
                        </select>
                    </div>

                </div>

            </div>


            {showWarning() && (
                <p style={{ color: "red" }}>
                    {t('qr_max')}
                </p>
            )}

            {qr_label_list_load ? (
                <div className="spinner-border text-primary mt-4" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            ) : (<>{showProperInputs(lan)}</>


            )}
        </div>
    );
};

export default ProductLabelFormMultiLanguage;
