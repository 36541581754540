import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
    .use(LanguageDetector) // Detects the user's language
    .use(initReactI18next) // Passes i18n down to react-i18next
    .init({
        resources: {
            en: {
                translation: {
                    "entry_not_found": "For selected language entry not found",
                    "create_new_entry_for_selected_language": "Create new entry for selected language",
                    "add_new_product": "Add new product",
                    "PHV_UPPERCASE": "AVERAGE NUTRITIONAL VALUES PER 100 ml",
                    "content_uppercase": "TABLE OF CONTENTS",
                    "name": "Name",
                    "category": "Category",
                    "origin": "Origin",
                    "product_info": "Product information",
                    "product_name_uppercase": "PRODUCT NAME",
                    "composition_uppercase": "LIST OF INGREDIENTS",
                    "details_uppercase": "DETAILS",
                    "new_entry": "New entry",
                    "entry_list": "List of existing articles",
                    "download_qr": "Download",
                    "edit": "Edit",
                    "save": "Save",
                    "delete": "Delete",
                    "cancel": "Cancel",
                    "yes": "Yes",
                    "no": "No",
                    "welcome": "Welcome",
                    "create_qr_account": "Create QR account",
                    "hello": "Hello",
                    "login": "Log in",
                    "register": "Register",
                    "logoff": "Log off",
                    "frontpage": "Frontpage",
                    "user": "User",
                    "first_name": "First Name",
                    "last_name": "Last Name",
                    "email": "Email",
                    "qr_account": "QR Account",
                    "creation_date": "Creation Date",
                    "valid_until": "Valid Until",
                    "max_qr_codes": "Max. Num. QR Codes",
                    "account_active": "Account Active",
                    "impressum": "Impressum",
                    "company": "Company",
                    "address": "Address",
                    "oib": "Tax number",
                    "phone": "Phone",
                    "new_entry": "New Entry",
                    "list_existing_items": "List Existing Items",
                    "product_name": "Product Name",
                    "composition": "Composition",
                    "ingredients": "Ingredients",
                    "nutrition_value": "Nutrition Value",
                    "energy_value_kj": "Energy Value (kJ)",
                    "energy_value_kcal": "Energy Value (kcal)",
                    "fat": "Fats",
                    "energy": "Energy",
                    "saturated_fat": "of which saturated fats",
                    "carbohydrates": "Carbohydrates",
                    "sugars": "of which sugars",
                    "protein": "Protein",
                    "salt": "Salt",
                    "allergens": "Allergens",
                    "sulfites": "Sulphites",
                    "manufacturer": "Manufacturer",
                    "save": "Save",
                    "delete": "Delete",
                    "cancel": "Cancel",
                    "edit": "Edit",
                    "qr_max": "The maximum number of QR codes has been created. To increase the number of available codes, please contact technical support at danijel@ordonovus.com",
                    "disclamer": "The displayed information is mandatory information based on Regulation (EU) 1308/2013 (Common Market Organization). The information is provided by the producer. Ordo Novus only provides the technical solution for fulfilling this obligation. Ordo Novus does not verify delivered information. The presented information was provided by the person responsible for the data set on behalf of the producer.",
                    "account_not_active": "Account is not active, contact tech support at danijel@ordonovus.com",
                    "add": "Add",
                    "close": "Close",
                    "about_product": "About",
                    "services": "Services",
                    "features": "Features",
                    "pricing": "Pricing",
                    "contact": "Contact",
                    "sub_tittle_text": "Digital labels for your products. The service of generating and maintaining unique QR codes - digital labels for food products",
                    "get_started": "Get Started",
                    "eu_reg_comp": "EU Regulation Compliance",
                    "labels_for_multiple_langs": "Labels for multiple languages",
                    "qr_modification": "Ability to modify QR label content",
                    "friendly_interface": "Intuitive and user friendly interface",
                    "qr_subtitle_3": "QR Codes - Digital labels",
                    "qr_about_t1": "QR Codes by Ordo Novus is a comprehensive digital labeling solution tailored to meet the needs of food producers and distributors. Our service ensures full compliance with EU regulations, providing a reliable and easy-to-use platform for generating and managing QR codes. With our solution, you can easily update product information, ensuring your labels are always accurate and up-to-date.",
                    "qr_about_t2": "Regulatory Compliance: Fully compliant with EU regulations (EU No 1308/2013 and EU No 1169/2011).",
                    "qr_about_t3": "Flexible Content Management: Update product details anytime, even after the QR code has been generated.",
                    "qr_about_t4": "User-Friendly Interface: An intuitive platform designed for ease of use, supporting both Croatian and English languages.",
                    "check_our": "Check Our",
                    "label_example": "Label Example",
                    "label_example_text": "An example of a label that appears when its QR code is scanned.",
                    "generating_qr": "Generating QR Codes",
                    "generating_qr_text": "A unique QR code is generated for each item. It is available as SVG, PNG, and URL to cover all needs, from design to implementation for printing on physical labels.",
                    "creating_a_digital_label": "Creating of a Digital Label",
                    "creating_a_digital_label_txt": "The content of the digital label, for which a unique QR code exists, can be modified as needed even after it has been created.",
                    "qr_hosting": "QR Hosting",
                    "qr_hosting_text": "Digital hosting of label content and maintenance of the label link and the link encoded in the unique QR code.",
                    "regulatory_compl": "Regulatory Compliance",
                    "regulatory_compl_txt": "Our labels are fully compliant with all relevant European Union regulations, including Regulation (EU) No 1308/2013, which governs the common organization of agricultural markets, and Regulation (EU) No 1169/2011, which ensures the provision of food information to consumers.",
                    "multiple_langs": "Support for Multiple Languages",
                    "multiple_langs_text": "Labels are available in multiple languages to ensure accessibility and understanding for diverse consumer markets across different regions.",
                    "f1": "EU Regulatory Compliance",
                    "f2": "Multi-language Support",
                    "f3": "Real-time Content Management",
                    "f4": "User-friendly Interface",
                    "f5": "Affordable Pricing",
                    "p1_10": "10 Labels",
                    "p1_40": "40 Labels",
                    "p1_40p": "40+ Labels",
                    "p2_s": "Small producers",
                    "p2_m": "Medium  producers",
                    "p2_l": "Large producers & Importers",
                    "p3": "Technical support",
                    "p4": "User manual",
                    "p5": "Functionality upgrades",
                    "p6": "Multiple languages",
                    "buy_now": "Buy Now",
                    "fa": "Frequently Asked",

                    "q": "Questions",
                    "q1": "What is Regulation (EU) No 1169/2011, and how does it affect food labeling?",
                    "a1": "Regulation (EU) No 1169/2011 ensures that consumers have clear and comprehensive information about the food they purchase, particularly regarding ingredients, allergens, and nutritional content. It mandates that labels must be easy to understand and accessible, with specific requirements for font size and language​ (Food Safety)​ (AGROLAB GROUP).",
                    "q2": "How does Regulation (EU) No 1308/2013 impact agricultural product markets?",
                    "a2": "Regulation (EU) No 1308/2013 establishes a common organization of agricultural markets within the EU, providing a framework for managing and supporting the production and trade of agricultural products. It includes provisions on marketing standards, crisis management, and direct payments to farmers​ (Food Safety)​ (AGROLAB GROUP)",
                    "q3": "How do QR codes improve product traceability?",
                    "a3": "QR codes can be linked to detailed product information, including origin, manufacturing process, and certifications, making it easier for consumers and businesses to trace the product's journey and ensure compliance with safety standards.",
                    "q4": "How easy is our product to use?",
                    "a4": "Our product is designed with user-friendliness in mind, featuring an intuitive interface that requires minimal technical knowledge. With straightforward navigation and clear instructions, users can easily generate, manage, and track QR codes, ensuring a seamless experience.",
                    "q5": "What security measures are in place for QR codes?",
                    "a5": "QR codes are encrypted and linked to secure websites, ensuring that sensitive information is protected. Additionally, they can be dynamically generated to prevent unauthorized access or duplication.",
                    "need_help_qm": "Need Help?",
                    "contact_us": "Contact Us",
                    "bt1": "Why Your Product Needs QR Codes",
                    "bt2": "QR codes provide an easy and efficient way to access detailed information about your products. Simply scan the QR code with your smartphone to instantly get product details. Our QR codes enhance the customer experience by providing a quick link to valuable information, ensuring you get the most out of our products. We offer services for creating, maintaining, and hosting QR codes to ensure you have a seamless experience from start to finish.",
                    "call_us": "Call us",
                    "generate_qr_title": "Generating QR Codes",
                    "generate_qr1": "The process of creating a unique QR code for your product E label is designed to be both simple and comprehensive, ensuring that all regulatory requirements are met with ease. Our intuitive user interface guides you through predefined fields, making it straightforward to enter the necessary product information while maintaining full compliance with industry standards.",
                    "generate_qr2": "Intuitive User Interface.",
                    "generate_qr3": "Unique QR Code Generation.",
                    "generate_qr4": "Automatic URL Creation",
                    "generate_qr5": "Each QR code generated is unique to your product, available in both PNG and SVG formats, ensuring flexibility in its application, whether for digital platforms or physical labels. Additionally, a unique URL is automatically created and embedded within the QR code. This URL leads directly to the product's E label, providing instant access to detailed product information for consumers and regulators alike.",
                    "cdl_title": "Creating a Digital Label",
                    "cdl_1": "The creation of a digital label is a critical step in ensuring that your product meets the latest regulatory requirements and provides clear, accessible information to consumers. Our platform makes this process seamless with predefined fields that guide you through the necessary steps.",
                    "cdl_2": "Predefined Regulatory Fields.",
                    "cdl_3": "Comprehensive Data Entry.",
                    "cdl_4": "Real-time Preview of Labels.",
                    "cdl_5": "By utilizing our intuitive tools, you can create digital labels that are fully compliant and easy to update. Whether you’re adding nutritional information, ingredients, or safety warnings, our platform ensures that your labels are accurate and up-to-date.",
                    "sfml_title": "Support for Multiple Languages",
                    "sfml_1": "In today’s global market, it's essential to offer product labels in multiple languages to reach a wider audience. Our platform provides robust support for creating and managing digital labels in various languages, ensuring your product is accessible to consumers around the world.",
                    "sfml_2": "Multi-language Input Support.",
                    "sfml_3": "Seamless Language Switching.",
                    "sfml_4": "Compliance with Regional Regulations.",
                    "sfml_5": "Whether your product is sold in a single country or across multiple regions, our platform ensures that your digital labels can be easily translated and adapted to meet the linguistic and regulatory needs of each market.",
                    "reg_comp_title": "Regulatory Compliance",
                    "reg_comp1": "Our platform is meticulously designed to ensure that your product labels fully comply with key EU regulations, including the EU Food Information to Consumers Regulation (EU FIC) - Regulation (EU) No 1169/2011, the General Product Safety Directive (2001/95/EC), and other relevant standards. These regulations are vital in guaranteeing that consumers receive clear, accurate, and comprehensive information about the products they purchase.",
                    "reg_comp2": "Compliance with EU Food Information to Consumers Regulation (EU FIC) for clear allergen, nutritional, and ingredient information.",
                    "reg_comp3": "Adherence to the General Product Safety Directive to ensure that your products are safe and meet EU market requirements.",
                    "reg_comp4": "Support for the EU Regulation on Nutrition and Health Claims (EC No 1924/2006), ensuring that any claims made on your labels are substantiated and legally compliant.",
                    "reg_comp5": "Compliance with Regulation (EC) No 1223/2009 on Cosmetic Products, helping you to meet all safety and labeling requirements for cosmetic goods.",
                    "reg_comp6": "Ensuring that medical devices meet the labeling standards set by EU Regulation 2017/745 on Medical Devices (MDR), ensuring patient safety and regulatory adherence.",
                    "reg_comp7": "By using our platform, you not only ensure that your labels are legally compliant, but you also gain peace of mind knowing that your products meet the highest standards of safety and transparency. Our commitment to regulatory compliance helps you build trust with your consumers and maintain your brand's reputation in a competitive market.",
                    "reg_comp8": "Start leveraging our tools today to create labels that are not only compliant but also enhance the consumer experience by providing clear, accessible information. Together, we can navigate the complexities of EU regulations and ensure that your products are always in line with the latest standards.",
                    "qr_hosting_title": "QR Hosting",
                    "qr_hosting_1": "QR code hosting is a crucial service that ensures your digital labels are always accessible and up-to-date. Our platform provides reliable and secure hosting solutions for your QR codes, ensuring they lead to the correct and current product information.",
                    "qr_hosting_2": "Secure QR Code Hosting.",
                    "qr_hosting_3": "Post Creation Data Updates.",
                    "qr_hosting_4": "Scalable Hosting Solutions.",
                    "qr_hosting_5": "With our hosting services, you can be confident that your QR codes will always direct consumers to the latest information about your products, no matter where they are scanned or viewed.",
                    "service_details_title": "Service Details",
                    "service_details_text": "Explore our range of services designed to meet your needs and help your business grow.",
                    "service_details": "Service Details",
                    "services_list": "Services List",
                    "download_catalog": "Download Catalog",
                    "have_question_qm": "Have a Question?",
                    "password": "Password",
                    "ltt": "Why Your Product Needs QR Codes?",
                    "lttxt1": "QR codes provide an easy and efficient way to access detailed information about your products. Simply scan the QR code with your smartphone to instantly get product details, special offers, and more. Our QR codes enhance the customer experience by providing a quick link to valuable information, ensuring you get the most out of our products.",
                    "lttxt2": "We offer services for creating, maintaining, and hosting QR codes to ensure you have a seamless experience from start to finish",
                    "forget_your_password": "Forgot your password? Contact Us",
                    "register_user_account": "Register User Account",
                    "name_label": "Name",
                    "surname": "Surname",
                    "register": "Register",
                    "entry_for_this_language_does_not_exist": "Entry for this language does not exist",
                    "not_in_use_all_caps": "NOT_IN_USE",
                    "naziv_label": "Name",
                }
            },

            hr: {
                translation: {
                    "entry_not_found": "Za odabrani jezik ne postoji zapis",
                    "create_new_entry_for_selected_language": "Kreiraj novi zapis za odabrani jezik",
                    "add_new_product": "Dodaj novi proizvod",
                    "PHV_UPPERCASE": "PROSJEČNA HRANJIVA VRIJEDNOST NA 100 ml",
                    "content_uppercase": "SADRŽAJ",
                    "name": "Naziv",
                    "category": "Kategorija",
                    "origin": "Porijeklo",
                    "product_info": "Podaci o proizvodu",
                    "product_name_uppercase": "NAZIV PROIZVODA",
                    "composition_uppercase": "SASTAV",
                    "details_uppercase": "POJEDINOSTI",
                    "new_entry": "Novi unos",
                    "entry_list": "Popis postojećih artikala",
                    "download_qr": "Preuzmi",
                    "edit": "Uredi",
                    "save": "Spremi",
                    "delete": "Obriši",
                    "cancel": "Poništi",
                    "yes": "Da",
                    "no": "Ne",
                    "create_qr_account": "Kreiraj QR Račun",
                    "welcome": "Dobrodošli",
                    "hello": "Bok",
                    "login": "Prijava",
                    "register": "Registracija",
                    "logoff": "Odjava",
                    "frontpage": "Naslovnica",
                    "user": "Korisnik",
                    "first_name": "Ime",
                    "last_name": "Prezime",
                    "email": "Email",
                    "qr_account": "QR račun",
                    "creation_date": "Datum kreiranja",
                    "valid_until": "Vrijedi do",
                    "max_qr_codes": "Max br. QR kodova",
                    "account_active": "Račun aktivan",
                    "impressum": "Impresum",
                    "company": "Kompanija",
                    "address": "Adresa",
                    "oib": "OIB",
                    "phone": "Telefon",
                    "new_entry": "Novi unos",
                    "list_existing_items": "Popis postojećih artikala",
                    "product_name": "Naziv proizvoda",
                    "composition": "Sastav",
                    "ingredients": "Sastojci",
                    "nutrition_value": "Prosječna hranjiva vrijednost",
                    "energy_value_kj": "Energetska vrijednost (kJ)",
                    "energy_value_kcal": "Energetska vrijednost (kcal)",
                    "fat": "Masti",
                    "energy": "Energija",
                    "saturated_fat": "Od kojih zasićene masne kiseline",
                    "carbohydrates": "Ugljikohidrati",
                    "sugars": "Od kojih šećeri",
                    "protein": "Bjelančevine",
                    "salt": "Sol",
                    "allergens": "Alergeni",
                    "sulfites": "Sulfidi",
                    "manufacturer": "Proizvođač",
                    "save": "Spremi",
                    "delete": "Obriši",
                    "cancel": "Poništi",
                    "edit": "Uredi",
                    "qr_max": "Kreiran je maksimalan broj QR kodova, za povećenje broja dostupnih kodova obratite se tehničkoj potpori na danijel@ordonovus.com",
                    "disclamer": "Prikazane informacije obvezne su informacije na temelju Uredbe (EU) 1308/2013 (Zajednička organizacija tržišta). Informacije dostavlja proizvođač. Ordo Novus samo daje tehničko rješenje za ispunjavanje te obveze na raspolaganje. Ordo Novus ne provjerava dostavljene informacije. Predočene informacije dostavila je odgovorna osoba od strane proizvođača.",
                    "account_not_active": "Račun je trenutno neaktivan, obratite se tehničkoj potpori na danijel@ordonovus.com",
                    "add": "Dodaj",
                    "close": "Zatvori",
                    "about_product": "O proizvodu",
                    "services": "Usluge",
                    "features": "Značajke",
                    "pricing": "Cijene",
                    "contact": "Kontakt",
                    "sub_tittle_text": "Digitalne labele za vaše proizvode. Usluga generiranja i održavanja jedinstvenih QR kodova - digitalnih labela za prehrambene proizvode",
                    "get_started": "Kreni",
                    "eu_reg_comp": "Usklađenost s EU regulativom",
                    "labels_for_multiple_langs": "Labele na više jezika",
                    "qr_modification": "Mogućnost izmjene sadržaja QR labela",
                    "friendly_interface": "Intuitivno i korisnički prilagođeno sučelje",
                    "qr_subtitle_3": "QR kodovi - Digitalne labele",
                    "qr_about_t1": "QR kodovi od Ordo Novus su sveobuhvatno rješenje za digitalno označavanje prilagođeno potrebama proizvođača i distributera hrane. Naša usluga osigurava potpunu usklađenost s EU regulativom, pružajući pouzdanu i jednostavnu platformu za generiranje i upravljanje QR labelama. S našim rješenjem možete lako ažurirati informacije o proizvodima, osiguravajući da vaše labele uvijek budu točne i ažurirane.",
                    "qr_about_t2": "Regulativna usklađenost: Potpuna usklađenost s EU regulativama (EU br. 1308/2013 i EU br. 1169/2011).",
                    "qr_about_t3": "Fleksibilno upravljanje sadržajem: Ažurirajte podatke o proizvodu u bilo kojem trenutku, čak i nakon što je QR kod generiran.",
                    "qr_about_t4": "Korisnički prilagođeno sučelje: Intuitivna platforma dizajnirana za jednostavno korištenje, podržava hrvatski i engleski jezik.",
                    "check_our": "Pogledajte",
                    "label_example": "Primjer labele",
                    "label_example_text": "Primjer labele koja se pojavljuje kada se skenira njen QR kod.",
                    "generating_qr": "Generiranje QR kodova",
                    "generating_qr_text": "Za svaki artikl generira se jedinstveni QR kod. Dostupan je kao SVG, PNG i URL kako bi se zadovoljile sve potrebe, od dizajna do implementacije za tiskanje na fizičke labele.",
                    "creating_a_digital_label": "Kreiranje digitalne labele",
                    "creating_a_digital_label_txt": "Sadržaj digitalne labele, za koju postoji jedinstveni QR kod, može se mijenjati prema potrebi čak i nakon što je stvorena.",
                    "qr_hosting": "QR hosting",
                    "qr_hosting_text": "Digitalni hosting sadržaja labele i održavanje poveznice labele te poveznice kodirane u jedinstvenom QR kodu.",
                    "regulatory_compl": "Regulativna usklađenost",
                    "regulatory_compl_txt": "Naše labele su u potpunosti usklađene sa svim relevantnim regulativama Europske unije, uključujući Uredbu (EU) br. 1308/2013, koja regulira zajedničko uređenje tržišta poljoprivrednih proizvoda, i Uredbu (EU) br. 1169/2011, koja osigurava pružanje informacija o hrani potrošačima.",
                    "multiple_langs": "Podrška za više jezika",
                    "multiple_langs_text": "Labele su dostupne na više jezika kako bi se osigurala dostupnost i razumijevanje za različita potrošačka tržišta u različitim regijama.",
                    "f1": "Usklađenost s EU regulativom",
                    "f2": "Podrška za više jezika",
                    "f3": "Upravljanje sadržajem u stvarnom vremenu",
                    "f4": "Korisnički prilagođeno sučelje",
                    "f5": "Pristupačne cijene",
                    "p1_10": "10 labela",
                    "p1_40": "40 labela",
                    "p1_40p": "40+ labela",
                    "p2_s": "Mali proizvođači",
                    "p2_m": "Srednji proizvođači",
                    "p2_l": "Veliki proizvođači i uvoznici",
                    "p3": "Tehnička podrška",
                    "p4": "Korisnički priručnik",
                    "p5": "Nadogradnje funkcionalnosti",
                    "p6": "Više jezika",
                    "buy_now": "Kupi sada",
                    "fa": "Često postavljana",
                    "q": "Pitanja",
                    "q1": "Što je Uredba (EU) br. 1169/2011 i kako utječe na označavanje hrane?",
                    "a1": "Uredba (EU) br. 1169/2011 osigurava da potrošači imaju jasne i sveobuhvatne informacije o hrani koju kupuju, posebno u vezi s sastojcima, alergenima i nutritivnim sadržajem. Propisuje da labele moraju biti jednostavne za razumijevanje i dostupne, s posebnim zahtjevima za veličinu fonta i jezik​ (Sigurnost hrane)​ (AGROLAB GROUP).",
                    "q2": "Kako Uredba (EU) br. 1308/2013 utječe na tržišta poljoprivrednih proizvoda?",
                    "a2": "Uredba (EU) br. 1308/2013 uspostavlja zajedničko uređenje tržišta poljoprivrednih proizvoda unutar EU-a, pružajući okvir za upravljanje i podršku proizvodnji i trgovini poljoprivrednih proizvoda. Uključuje odredbe o marketinškim standardima, upravljanju krizama i izravnim plaćanjima poljoprivrednicima​ (Sigurnost hrane)​ (AGROLAB GROUP).",
                    "q3": "Kako QR kodovi poboljšavaju sljedivost proizvoda?",
                    "a3": "QR kodovi mogu biti povezani s detaljnim informacijama o proizvodu, uključujući podrijetlo, proces proizvodnje i certifikate, što olakšava potrošačima i tvrtkama praćenje putovanja proizvoda i osiguranje usklađenosti sa sigurnosnim standardima.",
                    "q4": "Koliko je jednostavan naš proizvod za korištenje?",
                    "a4": "Naš proizvod je dizajniran s korisničkom jednostavnošću na umu, s intuitivnim sučeljem koje zahtijeva minimalno tehničko znanje. Uz jednostavnu navigaciju i jasne upute, korisnici mogu lako generirati, upravljati i pratiti QR kodove, osiguravajući besprijekorno iskustvo.",
                    "q5": "Koje sigurnosne mjere su na snazi za QR kodove?",
                    "a5": "QR kodovi su šifrirani i povezani sa sigurnim web stranicama, osiguravajući da su osjetljive informacije zaštićene. Dodatno, mogu se dinamički generirati kako bi se spriječio neovlašteni pristup ili dupliciranje.",
                    "need_help_qm": "Trebate pomoć?",
                    "contact_us": "Kontaktirajte nas",
                    "bt1": "Zašto vaš proizvod treba QR kodove",
                    "bt2": "QR kodovi pružaju jednostavan i učinkovit način pristupa detaljnim informacijama o vašim proizvodima. Jednostavno skenirajte QR kod svojim pametnim telefonom kako biste odmah dobili detalje o proizvodu. Naši QR kodovi poboljšavaju korisničko iskustvo pružajući brzu poveznicu na vrijedne informacije, osiguravajući da maksimalno iskoristite naše proizvode. Nudimo usluge kreiranja, održavanja i hostinga QR kodova kako bismo osigurali da imate besprijekorno iskustvo od početka do kraja.",
                    "call_us": "Nazovite nas",
                    "generate_qr_title": "Generiranje QR kodova",
                    "generate_qr1": "Proces stvaranja jedinstvenog QR koda za vašu E oznaku proizvoda osmišljen je tako da bude jednostavan i sveobuhvatan, osiguravajući da se svi regulatorni zahtjevi lako ispunjavaju. Naše intuitivno korisničko sučelje vodi vas kroz unaprijed definirana polja, što olakšava unos potrebnih informacija o proizvodu uz potpuno poštivanje industrijskih standarda.",
                    "generate_qr2": "Intuitivno korisničko sučelje.",
                    "generate_qr3": "Generiranje jedinstvenog QR koda.",
                    "generate_qr4": "Automatsko stvaranje URL-a",
                    "generate_qr5": "Svaki generirani QR kod je jedinstven za vaš proizvod, dostupan u PNG i SVG formatima, što osigurava fleksibilnost u njegovoj primjeni, bilo za digitalne platforme ili fizičke oznake. Osim toga, jedinstveni URL se automatski stvara i ugrađuje unutar QR koda. Ovaj URL vodi izravno do E oznake proizvoda, pružajući trenutan pristup detaljnim informacijama o proizvodu za potrošače i regulatore.",
                    "cdl_title": "Izrada Digitalne Labele",
                    "cdl_1": "Izrada digitalne labele ključan je korak kako biste osigurali da vaš proizvod zadovoljava najnovije regulatorne zahtjeve i pruža jasne, dostupne informacije potrošačima. Naša platforma čini ovaj proces jednostavnim s unaprijed definiranim poljima koja vas vode kroz potrebne korake.",
                    "cdl_2": "Unaprijed definirana regulatorna polja.",
                    "cdl_3": "Sveobuhvatan unos podataka.",
                    "cdl_4": "Pregled Labela u stvarnom vremenu.",
                    "cdl_5": "Korištenjem naših intuitivnih alata možete izraditi digitalne labele koje su potpuno usklađene i jednostavne za ažuriranje. Bilo da dodajete nutritivne informacije, sastojke ili upozorenja o sigurnosti, naša platforma osigurava da su vaše labele točne i ažurne.",
                    "sfml_title": "Podrška za Više Jezika",
                    "sfml_1": "Na današnjem globalnom tržištu bitno je ponuditi etikete proizvoda na više jezika kako biste dosegli širu publiku. Naša platforma pruža robusnu podršku za izradu i upravljanje digitalnim labelama na raznim jezicima, osiguravajući da je vaš proizvod dostupan potrošačima diljem svijeta.",
                    "sfml_2": "Podrška za unos na više jezika.",
                    "sfml_3": "Jednostavno prebacivanje jezika.",
                    "sfml_4": "Usklađenost s regionalnim propisima.",
                    "sfml_5": "Bilo da se vaš proizvod prodaje u jednoj zemlji ili u više regija, naša platforma osigurava da se vaše digitalne labele lako mogu prevesti i prilagoditi kako bi zadovoljile jezične i regulatorne potrebe svakog tržišta.",
                    "reg_comp_title": "Regulatorna Usklađenost",
                    "reg_comp1": "Naša platforma pažljivo je osmišljena kako bi osigurala da vaše labele proizvoda u potpunosti poštuju ključne EU propise, uključujući Uredbu EU o informiranju potrošača o hrani (EU FIC) - Uredbu (EU) br. 1169/2011, Opću direktivu o sigurnosti proizvoda (2001/95/EZ) i druge relevantne standarde. Ovi propisi su ključni za osiguranje da potrošači dobivaju jasne, točne i sveobuhvatne informacije o proizvodima koje kupuju.",
                    "reg_comp2": "Usklađenost s Uredbom EU o informiranju potrošača o hrani (EU FIC) za jasne informacije o alergenima, nutritivnim vrijednostima i sastojcima.",
                    "reg_comp3": "Poštivanje Opće direktive o sigurnosti proizvoda kako bi se osiguralo da su vaši proizvodi sigurni i da zadovoljavaju zahtjeve EU tržišta.",
                    "reg_comp4": "Podrška za Uredbu EU o prehrambenim i zdravstvenim tvrdnjama (EC br. 1924/2006), osiguravajući da su sve tvrdnje na vašim labelama utemeljene i zakonski usklađene.",
                    "reg_comp5": "Usklađenost s Uredbom (EZ) br. 1223/2009 o kozmetičkim proizvodima, pomažući vam da ispunite sve sigurnosne i zahtjeve označavanja za kozmetičke proizvode.",
                    "reg_comp6": "Osiguravanje da medicinski uređaji ispunjavaju standarde označavanja prema Uredbi EU 2017/745 o medicinskim uređajima (MDR), osiguravajući sigurnost pacijenata i poštivanje propisa.",
                    "reg_comp7": "Korištenjem naše platforme ne samo da osiguravate da su vaše labele zakonski usklađene, već i stječete mir znajući da vaši proizvodi zadovoljavaju najviše standarde sigurnosti i transparentnosti. Naša predanost regulatornoj usklađenosti pomaže vam izgraditi povjerenje kod potrošača i održati reputaciju vašeg brenda na konkurentnom tržištu.",
                    "reg_comp8": "Počnite koristiti naše alate već danas kako biste izradili labele koje nisu samo usklađene, već i poboljšavaju korisničko iskustvo pružanjem jasnih i dostupnih informacija. Zajedno možemo navigirati kroz složenosti EU propisa i osigurati da vaši proizvodi uvijek budu u skladu s najnovijim standardima.",
                    "qr_hosting_title": "QR Hosting",
                    "qr_hosting_1": "Hosting QR kodova ključna je usluga koja osigurava da su vaše digitalne labele uvijek dostupne i ažurirane. Naša platforma pruža pouzdana i sigurna rješenja za hosting vaših QR kodova, osiguravajući da vode do točnih i aktualnih informacija o proizvodu.",
                    "qr_hosting_2": "Siguran hosting QR kodova.",
                    "qr_hosting_3": "Ažuriranja podataka nakon kreiranja.",
                    "qr_hosting_4": "Skalabilna rješenja za hosting.",
                    "qr_hosting_5": "Uz naše usluge hostinga, možete biti sigurni da će vaši QR kodovi uvijek usmjeravati potrošače na najnovije informacije o vašim proizvodima, bez obzira na to gdje su skenirani ili pregledani.",
                    "service_details_title": "Detalji Usluge",
                    "service_details_text": "Istražite našu ponudu usluga osmišljenih kako bi zadovoljile vaše potrebe i pomogle vašem poslovanju da raste.",
                    "service_details": "Detalji Usluge",
                    "services_list": "Popis Usluga",
                    "download_catalog": "Preuzmite Katalog",
                    "have_question_qm": "Pošaljite upit",
                    "password": "Lozinka",
                    "ltt": "Zašto Vaš proizvod treba QR kodove?",
                    "lttxt1": "QR kodovi pružaju jednostavan i učinkovit način za pristup detaljnim informacijama o Vašim proizvodima. Jednostavno skenirajte QR kod svojim pametnim telefonom kako biste odmah dobili detalje o proizvodu, posebne ponude i još mnogo toga. Naši QR kodovi poboljšavaju korisničko iskustvo pružajući brz link do vrijednih informacija, osiguravajući da izvučete maksimum iz naših proizvoda.",
                    "lttxt2": "We offer services for creating, maintaining, and hosting QR codes to ensure you have a seamless experience from start to finish",
                    "forget_your_password": "Zaboravili ste lozinku? Kontaktirajte nas!",
                    "register_user_account": "Registracija Korisničkog Računa",
                    "name": "Ime",
                    "surname": "Prezime",
                    "register": "Registracija",
                    "entry_for_this_language_does_not_exist": "Unos za ovaj jezik ne postoji",
                    "not_in_use_all_caps": "NIJE U UPOTREBI",
                    "naziv_label": "Ime",
                }
            },
            // Add more languages here

            sk: {
                translation: {
                    "entry_not_found": "Pre vybraný jazyk záznam nenájdený",
                    "create_new_entry_for_selected_language": "Vytvoriť nový záznam pre vybraný jazyk",
                    "add_new_product": "Pridať nový produkt",
                    "PHV_UPPERCASE_OLD": "PRIEMERNÉ VÝŽIVOVÉ HODNOTY NA 100 ml",
                    "PHV_UPPERCASE": "VÝŽIVOVÉ ÚDAJE NA 100 ml",
                    "content_uppercase": "OBSAHUJE",
                    "name": "Meno",
                    //"category": "Kategória",
                    //"origin": "Pôvod",
                    "category": "Pôvod",
                    "origin": "Fľašovateľ",
                    "product_info": "Informácie o produkte",
                    "product_name_uppercase": "NÁZOV PRODUKTU",
                    "composition_uppercase": "ZLOŽENIE",
                    "details_uppercase": "PODROBNOSTI",
                    "new_entry": "Nový záznam",
                    "entry_list": "Zoznam existujúcich položiek",
                    "download_qr": "Stiahnuť",
                    "edit": "Upraviť",
                    "save": "Uložiť",
                    "delete": "Vymazať",
                    "cancel": "Zrušiť",
                    "yes": "Áno",
                    "no": "Nie",
                    "create_qr_account": "Vytvoriť QR účet",
                    "welcome": "Vitajte",
                    "hello": "Ahoj",
                    "login": "Prihlásiť sa",
                    "register": "Registrovať sa",
                    "logoff": "Odhlásiť sa",
                    "frontpage": "Úvodná stránka",
                    "user": "Užívateľ",
                    "first_name": "Meno",
                    "last_name": "Priezvisko",
                    "email": "Email",
                    "qr_account": "QR účet",
                    "creation_date": "Dátum vytvorenia",
                    "valid_until": "Platné do",
                    "max_qr_codes": "Max. počet QR kódov",
                    "account_active": "Účet aktívny",
                    "impressum": "Impresum",
                    "company": "Spoločnosť",
                    "address": "Adresa spoločnosti",
                    "oib": "Daňové číslo",
                    "phone": "Telefón",
                    "new_entry": "Nový záznam",
                    "list_existing_items": "Zoznam existujúcich položiek",
                    "product_name": "Názov produktu",
                    "composition": "Zloženie",
                    "ingredients": "Ingrediencie",
                    "nutrition_value": "Nutričná hodnota",
                    "energy_value_kj": "Energetická hodnota (kJ)",
                    "energy_value_kcal": "Energetická hodnota (kcal)",
                    "fat": "Tuky",
                    "energy": "Energia",
                    "saturated_fat": "vrátane nasýtene mastné kyseliny",
                    "carbohydrates": "Sacharidy",
                    "sugars": "z toho cukry",
                    "protein": "Bielkoviny",
                    "salt": "Soľ",
                    "allergens": "Alergény",
                    "sulfites": "Siričitany",
                    "manufacturer": "Názov spoločnosti",
                    "save": "Uložiť",
                    "delete": "Vymazať",
                    "cancel": "Zrušiť",
                    "edit": "Upraviť",
                    "qr_max": "Bol vytvorený maximálny počet QR kódov. Ak chcete zvýšiť počet dostupných kódov, kontaktujte technickú podporu na danijel@ordonovus.com",
                    "disclamer": "Zobrazené informácie sú povinné informácie na základe nariadenia (EÚ) 1308/2013 (Spoločná organizácia trhu). Informácie poskytuje výrobca.Ordo Novus poskytuje len technické riešenie na splnenie tejto povinnosti. Ordo Novus neoveruje doručené informácie. Predložené informácieposkytol zodpovedný výrobca.",
                    "account_not_active": "Účet nie je aktívny, kontaktujte technickú podporu na danijel@ordonovus.com",
                    "add": "Pridať",
                    "close": "Zatvoriť",
                    "about_product": "O produkte",
                    "services": "Služby",
                    "features": "Funkcie",
                    "pricing": "Ceny",
                    "contact": "Kontakt",
                    "sub_tittle_text": "Digitálne štítky pre vaše produkty. Služba generovania a správy jedinečných QR kódov - digitálnych štítkov pre potravinárske produkty",
                    "get_started": "Začať",
                    "eu_reg_comp": "Súlad s reguláciami EÚ",
                    "labels_for_multiple_langs": "Štítky pre viacero jazykov",
                    "qr_modification": "Možnosť úpravy obsahu QR štítku",
                    "friendly_interface": "Intuitívne a užívateľsky prívetivé rozhranie",
                    "qr_subtitle_3": "QR Kódy - Digitálne štítky",
                    "qr_about_t1": "QR kódy od Ordo Novus sú komplexným riešením digitálneho označovania prispôsobeným potrebám výrobcov a distribútorov potravín. Naša služba zabezpečuje plný súlad s reguláciami EÚ, poskytujúc spoľahlivú a ľahko použiteľnú platformu pre generovanie a správu QR kódov. S naším riešením môžete ľahko aktualizovať informácie o produktoch, čím zabezpečíte, že vaše štítky budú vždy presné a aktuálne.",
                    "qr_about_t2": "Súlad s reguláciami: Plne súladné s reguláciami EÚ (EÚ č. 1308/2013 a EÚ č. 1169/2011).",
                    "qr_about_t3": "Flexibilné riadenie obsahu: Aktualizujte detaily produktu kedykoľvek, aj po vygenerovaní QR kódu.",
                    "qr_about_t4": "Užívateľsky prívetivé rozhranie: Intuitívna platforma navrhnutá pre jednoduché použitie, podporujúca chorvátčinu a angličtinu.",
                    "check_our": "Skontrolujte naše",
                    "label_example": "Príklad štítku",
                    "label_example_text": "Príklad štítku, ktorý sa zobrazuje pri naskenovaní jeho QR kódu.",
                    "generating_qr": "Generovanie QR kódov",
                    "generating_qr_text": "Pre každý produkt sa generuje jedinečný QR kód. K dispozícii je ako SVG, PNG a URL, aby pokryl všetky potreby, od dizajnu po implementáciu na tlač fyzických štítkov.",
                    "creating_a_digital_label": "Vytváranie digitálneho štítku",
                    "creating_a_digital_label_txt": "Obsah digitálneho štítku, pre ktorý existuje jedinečný QR kód, môže byť upravený podľa potreby aj po jeho vytvorení.",
                    "qr_hosting": "QR hosting",
                    "qr_hosting_text": "Digitálny hosting obsahu štítku a údržba odkazu štítku a odkazu zakódovaného v jedinečnom QR kóde.",
                    "regulatory_compl": "Súlad s reguláciami",
                    "regulatory_compl_txt": "Naše štítky sú plne v súlade so všetkými relevantnými reguláciami Európskej únie, vrátane Nariadenia (EÚ) č. 1308/2013, ktoré upravuje spoločnú organizáciu trhov poľnohospodárskych výrobkov, a Nariadenia (EÚ) č. 1169/2011, ktoré zabezpečuje poskytovanie informácií o potravinách spotrebiteľom.",
                    "multiple_langs": "Podpora viacerých jazykov",
                    "multiple_langs_text": "Štítky sú dostupné v rôznych jazykoch, aby sa zabezpečila dostupnosť a porozumenie pre rôzne spotrebiteľské trhy v rôznych regiónoch.",
                    "f1": "Súlad s reguláciami EÚ",
                    "f2": "Podpora viacerých jazykov",
                    "f3": "Riadenie obsahu v reálnom čase",
                    "f4": "Užívateľsky prívetivé rozhranie",
                    "f5": "Cenovo dostupné ceny",
                    "p1_10": "10 Štítkov",
                    "p1_40": "40 Štítkov",
                    "p1_40p": "40+ Štítkov",
                    "p2_s": "Malí výrobcovia",
                    "p2_m": "Strední výrobcovia",
                    "p2_l": "Veľkí výrobcovia a dovozcovia",
                    "p3": "Technická podpora",
                    "p4": "Užívateľská príručka",
                    "p5": "Vylepšenia funkčnosti",
                    "p6": "Viacero jazykov",
                    "buy_now": "Kúpiť teraz",
                    "fa": "Často kladené otázky",
                    "q": "Otázky",
                    "q1": "Čo je Nariadenie (EÚ) č. 1169/2011 a ako ovplyvňuje označovanie potravín?",
                    "a1": "Nariadenie (EÚ) č. 1169/2011 zabezpečuje, že spotrebitelia majú jasné a komplexné informácie o potravinách, ktoré nakupujú, najmä pokiaľ ide o zložky, alergény a nutričný obsah. Predpisuje, že štítky musia byť ľahko pochopiteľné a prístupné, s konkrétnymi požiadavkami na veľkosť písma a jazyk​ (Bezpečnosť potravín)​ (AGROLAB GROUP).",
                    "q2": "Ako Nariadenie (EÚ) č. 1308/2013 ovplyvňuje trhy poľnohospodárskych produktov?",
                    "a2": "Nariadenie (EÚ) č. 1308/2013 ustanovuje spoločnú organizáciu trhov poľnohospodárskych produktov v rámci EÚ, poskytujúc rámec pre riadenie a podporu výroby a obchodu poľnohospodárskych produktov. Zahŕňa ustanovenia o marketingových normách, riadení krízových situácií a priame platby poľnohospodárom​ (Bezpečnosť potravín)​ (AGROLAB GROUP)",
                    "q3": "Ako QR kódy zlepšujú sledovateľnosť produktov?",
                    "a3": "QR kódy môžu byť prepojené s podrobnými informáciami o produkte, vrátane pôvodu, výrobného procesu a certifikácií, čo uľahčuje spotrebiteľom a firmám sledovanie cesty produktu a zabezpečenie súladu s bezpečnostnými normami.",
                    "q4": "Ako je náš produkt jednoduchý na používanie?",
                    "a4": "Náš produkt je navrhnutý s ohľadom na užívateľskú prívetivosť, s intuitívnym rozhraním, ktoré nevyžaduje technické znalosti. S jednoduchou navigáciou a jasnými pokynmi môžu používatelia ľahko generovať, spravovať a sledovať QR kódy, čím zabezpečia bezproblémovú skúsenosť.",
                    "q5": "Aké bezpečnostné opatrenia sú implementované pre QR kódy?",
                    "a5": "QR kódy sú šifrované a prepojené s bezpečnými webovými stránkami, čo zabezpečuje ochranu citlivých informácií. Okrem toho môžu byť dynamicky generované, aby sa zabránilo neoprávnenému prístupu alebo duplikácii.",
                    "need_help_qm": "Potrebujete pomoc?",
                    "contact_us": "Kontaktujte nás",
                    "bt1": "Prečo váš produkt potrebuje QR kódy",
                    "bt2": "QR kódy poskytujú jednoduchý a efektívny spôsob, ako získať podrobné informácie o vašich produktoch. Stačí naskenovať QR kód pomocou vášho smartfónu a okamžite získať detaily o produkte. Naše QR kódy vylepšujú zákaznícku skúsenosť poskytovaním rýchleho odkazu na cenné informácie, čím zabezpečujú, že získate maximum z našich produktov. Ponúkame služby na vytváranie, správu a hosťovanie QR kódov, aby ste mali bezproblémovú skúsenosť od začiatku až do konca.",
                    "call_us": "Zavolajte nám",
                    "generate_qr_title": "Generovanie QR kódov",
                    "generate_qr1": "Proces vytvárania jedinečného QR kódu pre váš produkt E štítok je navrhnutý tak, aby bol jednoduchý a komplexný, čím zabezpečuje, že všetky regulačné požiadavky sú splnené s ľahkosťou. Naše intuitívne užívateľské rozhranie vás prevedie preddefinovanými poľami, čím je jednoduché zadať potrebné informácie o produkte, pričom sa zachová plný súlad s normami odvetvia.",
                    "generate_qr2": "Intuitívne užívateľské rozhranie.",
                    "generate_qr3": "Generovanie jedinečného QR kódu.",
                    "generate_qr4": "Automatické vytvorenie URL",
                    "generate_qr5": "Každý vygenerovaný QR kód je jedinečný pre váš produkt, k dispozícii vo formátoch PNG a SVG, čo zabezpečuje flexibilitu pri jeho aplikácii, či už na digitálnych platformách alebo na fyzických štítkoch. Okrem toho sa automaticky vytvorí jedinečné URL, ktoré je vložené do QR kódu. Toto URL vedie priamo na E štítok produktu, čím poskytuje okamžitý prístup k podrobným informáciám o produkte pre spotrebiteľov aj regulátorov.",
                    "cdl_title": "Vytváranie digitálneho štítku",
                    "cdl_1": "Vytvorenie digitálneho štítku je kľúčovým krokom pri zabezpečení, že váš produkt spĺňa najnovšie regulačné požiadavky a poskytuje jasné, prístupné informácie spotrebiteľom. Naša platforma tento proces zabezpečuje bez problémov s preddefinovanými poľami, ktoré vás prevedú potrebnými krokmi.",
                    "cdl_2": "Preddefinované regulačné polia.",
                    "cdl_3": "Komplexný vstup dát.",
                    "cdl_4": "Náhľad štítkov v reálnom čase.",
                    "cdl_5": "Využitím našich intuitívnych nástrojov môžete vytvárať digitálne štítky, ktoré sú plne v súlade a ľahko aktualizovateľné. Či už pridávate nutričné informácie, zložky alebo varovania o bezpečnosti, naša platforma zabezpečuje, že vaše štítky sú presné a aktuálne.",
                    "sfml_title": "Podpora viacerých jazykov",
                    "sfml_1": "V dnešnom globálnom trhu je nevyhnutné ponúkať produktové štítky v rôznych jazykoch, aby ste oslovili širšie publikum. Naša platforma poskytuje silnú podporu na vytváranie a správu digitálnych štítkov v rôznych jazykoch, čím zabezpečujeme, že váš produkt bude prístupný spotrebiteľom po celom svete.",
                    "sfml_2": "Podpora vstupu viacerých jazykov.",
                    "sfml_3": "Bezproblémové prepínanie jazykov.",
                    "sfml_4": "Súlad s regionálnymi predpismi.",
                    "sfml_5": "Či už sa váš produkt predáva v jednej krajine alebo v rôznych regiónoch, naša platforma zabezpečuje, že vaše digitálne štítky môžu byť ľahko preložené a prispôsobené tak, aby spĺňali jazykové a regulačné požiadavky každého trhu.",
                    "reg_comp_title": "Súlad s reguláciami",
                    "reg_comp1": "Naša platforma je starostlivo navrhnutá tak, aby vaše produktové štítky plne vyhovovali kľúčovým reguláciám EÚ, vrátane Nariadenia EÚ o informáciách o potravinách spotrebiteľom (EÚ FIC) - Nariadenie (EÚ) č. 1169/2011, Smernice o všeobecnej bezpečnosti produktov (2001/95/ES) a ďalších relevantných noriem. Tieto regulácie sú kľúčové pre zabezpečenie, že spotrebitelia dostanú jasné, presné a komplexné informácie o produktoch, ktoré nakupujú.",
                    "reg_comp2": "Súlad s Nariadením EÚ o informáciách o potravinách spotrebiteľom (EÚ FIC) pre jasné informácie o alergénoch, nutričných hodnotách a zložkách.",
                    "reg_comp3": "Dodržiavanie Smernice o všeobecnej bezpečnosti produktov na zabezpečenie, že vaše produkty sú bezpečné a spĺňajú požiadavky trhu EÚ.",
                    "reg_comp4": "Podpora Nariadenia EÚ o výživových a zdravotných tvrdeniach (EÚ č. 1924/2006), zabezpečujúca, že akékoľvek tvrdenia na vašich štítkoch sú podložené a legálne.",
                    "reg_comp5": "Súlad s Nariadením (ES) č. 1223/2009 o kozmetických výrobkoch, ktoré vám pomáha splniť všetky bezpečnostné a označovacie požiadavky pre kozmetické výrobky.",
                    "reg_comp6": "Zabezpečenie, že zdravotnícke pomôcky spĺňajú požiadavky označovania podľa Nariadenia EÚ č. 2017/745 o zdravotníckych pomôckach (MDR), zabezpečujúc bezpečnosť pacientov a súlad s predpismi.",
                    "reg_comp7": "Použitím našej platformy nielen zabezpečíte, že vaše štítky sú právne v súlade, ale aj získate pokoj v duši, pretože vaše produkty spĺňajú najvyššie štandardy bezpečnosti a transparentnosti. Náš záväzok k regulačnému súladu vám pomáha budovať dôveru medzi spotrebiteľmi a udržiavať povesť vašej značky na konkurenčnom trhu.",
                    "reg_comp8": "Začnite využívať naše nástroje dnes na vytváranie štítkov, ktoré sú nielen v súlade, ale aj zlepšujú zákaznícku skúsenosť poskytovaním jasných a prístupných informácií. Spoločne môžeme navigovať komplexnosťou regulácií EÚ a zabezpečiť, že vaše produkty sú vždy v súlade s najnovšími normami.",
                    "qr_hosting_title": "QR Hosting",
                    "qr_hosting_1": "Hosting QR kódov je kľúčová služba, ktorá zabezpečuje, že vaše digitálne štítky sú vždy prístupné a aktuálne. Naša platforma poskytuje spoľahlivé a bezpečné riešenia hostingu pre vaše QR kódy, čím zabezpečuje, že vedú k správnym a aktuálnym informáciám o produkte.",
                    "qr_hosting_2": "Bezpečný hosting QR kódov.",
                    "qr_hosting_3": "Aktualizácie údajov po vytvorení.",
                    "qr_hosting_4": "Škálovateľné hostingové riešenia.",
                    "qr_hosting_5": "S našimi hostingovými službami si môžete byť istí, že vaše QR kódy vždy nasmerujú spotrebiteľov na najnovšie informácie o vašich produktoch, nech už sú naskenované alebo zobrazené kdekoľvek.",
                    "service_details_title": "Podrobnosti služby",
                    "service_details_text": "Prezkúmajte našu ponuku služieb navrhnutých tak, aby vyhovovali vašim potrebám a pomohli vašemu podnikaniu rásť.",
                    "service_details": "Podrobnosti služby",
                    "services_list": "Zoznam služieb",
                    "download_catalog": "Stiahnuť katalóg",
                    "have_question_qm": "Máte otázku?",
                    "password": "Heslo",
                    "ltt": "Prečo váš produkt potrebuje QR kódy?",
                    "lttxt1": "QR kódy poskytujú jednoduchý a efektívny spôsob, ako získať podrobné informácie o vašich produktoch. Stačí naskenovať QR kód pomocou vášho smartfónu a okamžite získať detaily o produkte, špeciálne ponuky a ďalšie. Naše QR kódy vylepšujú zákaznícku skúsenosť poskytovaním rýchleho odkazu na cenné informácie, čím zabezpečujú, že získate maximum z našich produktov.",
                    "lttxt2": "Ponúkame služby na vytváranie, správu a hosting QR kódov, aby ste mali bezproblémovú skúsenosť od začiatku až do konca.",
                    "forget_your_password": "Zabudli ste svoje heslo? Kontaktujte nás",
                    "register_user_account": "Registrovať používateľský účet",
                    "name_label": "Meno",
                    "surname": "Priezvisko",
                    "register": "Registrovať",
                    "entry_for_this_language_does_not_exist": "Záznam pre tento jazyk neexistuje",
                    "not_in_use_all_caps": "NEPOUŽÍVANÉ",
                    "naziv_label": "Názov",
                }
            },

            //Sveedish
            sv: {
                translation: {
                    "entry_not_found": "För vald språk, ingen post hittades",
                    "create_new_entry_for_selected_language": "Skapa ny post för vald språk",
                    "add_new_product": "Lägg till ny produkt",
                    "PHV_UPPERCASE": "GENOMSNITTLIGA NÄRINGSVÄRDEN PER 100 ml",
                    "content_uppercase": "NÄRINGSINNEHÅLL",
                    "name": "Namn",
                    "category": "Kategori",
                    "origin": "Ursprung",
                    "product_info": "Produktinformation",
                    "product_name_uppercase": "PRODUKTNAMN",
                    "composition_uppercase": "FÖRTECKNING ÖVER INGRIDIENSER",
                    "details_uppercase": "DETALJER",
                    "new_entry": "Ny post",
                    "entry_list": "Lista över befintliga artiklar",
                    "download_qr": "Ladda ner",
                    "edit": "Redigera",
                    "save": "Spara",
                    "delete": "Radera",
                    "cancel": "Avbryt",
                    "yes": "Ja",
                    "no": "Nej",
                    "welcome": "Välkommen",
                    "create_qr_account": "Skapa QR-konto",
                    "hello": "Hej",
                    "login": "Logga in",
                    "register": "Registrera",
                    "logoff": "Logga ut",
                    "frontpage": "Startsida",
                    "user": "Användare",
                    "first_name": "Förnamn",
                    "last_name": "Efternamn",
                    "email": "E-MAIL",
                    "qr_account": "QR-konto",
                    "creation_date": "Skapelsedatum",
                    "valid_until": "Giltig till",
                    "max_qr_codes": "Max. antal QR-koder",
                    "account_active": "Konto aktivt",
                    "impressum": "Impressum",
                    "company": "Företag",
                    "address": "FÖRETAGETS ADRESS",
                    "oib": "SKATTNUMMER",
                    "phone": "TELEFON",
                    "new_entry": "Ny post",
                    "list_existing_items": "Lista befintliga objekt",
                    "product_name": "Produktnamn",
                    "composition": "Komposition",
                    "ingredients": "Ingredienser",
                    "nutrition_value": "Näringsvärde",
                    "energy_value_kj": "Energi (kJ)",
                    "energy_value_kcal": "Energi (kcal)",
                    "fat": "Fetter",
                    "energy": "Energi",
                    "saturated_fat": "Varav mättade fetter",
                    "carbohydrates": "Kolhydrater",
                    "sugars": "Varav sockerarter",
                    "protein": "Protein",
                    "salt": "Salt",
                    "allergens": "Allergens",
                    "sulfites": "Sulphite",
                    "manufacturer": "FÖRETAGETS NAMN/PRODUCENT",
                    "save": "Spara",
                    "delete": "Radera",
                    "cancel": "Avbryt",
                    "edit": "Redigera",
                    "qr_max": "Det maximala antalet QR-koder har skapats. För att öka antalet tillgängliga koder, vänligen kontakta teknisk support på danijel@ordonovus.com",
                    "disclamer": "Den visade informationen är obligatorisk information baserad på förordning (EU) 1308/2013 (Common Market Organization). Informationen tillhandahålls av producenten. SONEXAPP tillhandahåller endast den tekniska lösningen för att uppfylla denna skyldighet. SONEXAPP verifierar inte levererad information. Den presenterade informationen lämnades av den person som ansvarar för datauppsättningen på uppdrag av producenten.",
                    "account_not_active": "Kontot är inte aktivt, kontakta teknisk support på danijel@ordonovus.com",
                    "add": "Lägg till",
                    "close": "Stäng",
                    "about_product": "Om produkten",
                    "services": "Tjänster",
                    "features": "Funktioner",
                    "pricing": "Prissättning",
                    "contact": "Kontakt",
                    "sub_tittle_text": "Digitala etiketter för dina produkter. Tjänsten för att skapa och underhålla unika QR-koder – digitala etiketter för livsmedelsprodukter",
                    "get_started": "Kom igång",
                    "eu_reg_comp": "EU-regelkompatibilitet",
                    "labels_for_multiple_langs": "Etiketter för flera språk",
                    "qr_modification": "Möjlighet att modifiera QR-etikettens innehåll",
                    "friendly_interface": "Intuitivt och användarvänligt gränssnitt",
                    "qr_subtitle_3": "QR-koder - Digitala etiketter",
                    "qr_about_t1": "QR-koder från Ordo Novus är en omfattande lösning för digital etikettering som är anpassad för att möta behoven hos livsmedelsproducenter och distributörer. Vår tjänst säkerställer fullständig efterlevnad av EU-regler, vilket ger en pålitlig och användarvänlig plattform för att skapa och hantera QR-koder. Med vår lösning kan du enkelt uppdatera produktinformation och säkerställa att dina etiketter alltid är korrekta och uppdaterade.",
                    "qr_about_t2": "Regelkompatibilitet: Fullt förenlig med EU-regler (EU nr 1308/2013 och EU nr 1169/2011).",
                    "qr_about_t3": "Flexibel innehållshantering: Uppdatera produktinformation när som helst, även efter att QR-koden har skapats.",
                    "qr_about_t4": "Användarvänligt gränssnitt: En intuitiv plattform designad för enkel användning, som stöder både kroatiska och engelska språk.",
                    "check_our": "Kolla på vår",
                    "label_example": "Etikettexempel",
                    "label_example_text": "Ett exempel på en etikett som visas när QR-koden skannas.",
                    "generating_qr": "Generera QR-koder",
                    "generating_qr_text": "En unik QR-kod genereras för varje artikel. Den är tillgänglig som SVG, PNG och URL för att täcka alla behov, från design till implementering för tryckning på fysiska etiketter.",
                    "creating_a_digital_label": "Skapa en digital etikett",
                    "creating_a_digital_label_txt": "Innehållet i den digitala etiketten, för vilken en unik QR-kod finns, kan ändras vid behov även efter att den skapats.",
                    "qr_hosting": "QR-värdskap",
                    "qr_hosting_text": "Digital värdskap av etikettinnehåll och underhåll av etikettlänken och länken som är kodad i den unika QR-koden.",
                    "regulatory_compl": "Regelkompatibilitet",
                    "regulatory_compl_txt": "Våra etiketter är helt förenliga med alla relevanta EU-regler, inklusive Förordning (EU) nr 1308/2013, som styr den gemensamma organisationen för jordbruksmarknader, och Förordning (EU) nr 1169/2011, som säkerställer att livsmedelsinformation tillhandahålls till konsumenter.",
                    "multiple_langs": "Stöd för flera språk",
                    "multiple_langs_text": "Etiketter finns på flera språk för att säkerställa tillgänglighet och förståelse för olika konsumentmarknader i olika regioner.",
                    "f1": "EU-regelkompatibilitet",
                    "f2": "Flerspråkigt stöd",
                    "f3": "Innehållshantering i realtid",
                    "f4": "Användarvänligt gränssnitt",
                    "f5": "Prisvärd prissättning",
                    "p1_10": "10 etiketter",
                    "p1_40": "40 etiketter",
                    "p1_40p": "40+ etiketter",
                    "p2_s": "Små producenter",
                    "p2_m": "Medelstora producenter",
                    "p2_l": "Stora producenter & importörer",
                    "p3": "Teknisk support",
                    "p4": "Användarmanual",
                    "p5": "Funktionalitetsuppgraderingar",
                    "p6": "Flera språk",
                    "buy_now": "Köp nu",
                    "fa": "Vanliga frågor",
                    "q": "Frågor",
                    "q1": "Vad är Förordning (EU) Nr 1169/2011, och hur påverkar den livsmedelsmärkning?",
                    "a1": "Förordning (EU) Nr 1169/2011 säkerställer att konsumenter får tydlig och omfattande information om de livsmedel de köper, särskilt om ingredienser, allergener och näringsinnehåll. Den kräver att etiketter ska vara lätta att förstå och tillgängliga, med specifika krav på textstorlek och språk (Livsmedelssäkerhet) (AGROLAB GROUP).",
                    "q2": "Hur påverkar Förordning (EU) Nr 1308/2013 jordbruksproduktmarknader?",
                    "a2": "Förordning (EU) Nr 1308/2013 etablerar en gemensam organisation för jordbruksmarknader inom EU och skapar en ram för att hantera och stödja produktion och handel med jordbruksprodukter. Den innehåller bestämmelser om marknadsföringsstandarder, krishantering och direkta betalningar till jordbrukare (Livsmedelssäkerhet) (AGROLAB GROUP).",
                    "q3": "Hur förbättrar QR-koder spårbarheten för produkter?",
                    "a3": "QR-koder kan kopplas till detaljerad produktinformation, inklusive ursprung, tillverkningsprocess och certifieringar, vilket gör det enklare för konsumenter och företag att spåra produktens resa och säkerställa att den uppfyller säkerhetsstandarder.",
                    "q4": "Hur lätt är det att använda vår produkt?",
                    "a4": "Vår produkt är designad med användarvänlighet i åtanke, med ett intuitivt gränssnitt som kräver minimal teknisk kunskap. Med enkel navigering och tydliga instruktioner kan användare enkelt generera, hantera och spåra QR-koder, vilket säkerställer en smidig upplevelse.",
                    "q5": "Vilka säkerhetsåtgärder finns för QR-koder?",
                    "a5": "QR-koder är krypterade och kopplade till säkra webbplatser, vilket säkerställer att känslig information skyddas. Dessutom kan de genereras dynamiskt för att förhindra obehörig åtkomst eller duplicering.",
                    "need_help_qm": "Behöver du hjälp?",
                    "contact_us": "Kontakta oss",
                    "bt1": "Varför ditt produkt behöver QR-koder",
                    "bt2": "QR-koder ger ett enkelt och effektivt sätt att få detaljerad information om dina produkter. Skanna bara QR-koden med din smartphone för att omedelbart få produktinformation. Våra QR-koder förbättrar kundupplevelsen genom att ge en snabb länk till värdefull information, så att du får ut det mesta av våra produkter. Vi erbjuder tjänster för att skapa, underhålla och vara värd för QR-koder för att säkerställa att du har en sömlös upplevelse från början till slut.",
                    "call_us": "Ring oss",
                    "generate_qr_title": "Generera QR-koder",
                    "generate_qr1": "Processen för att skapa en unik QR-kod för din produkt E-etikett är utformad för att vara både enkel och omfattande, vilket säkerställer att alla regleringskrav uppfylls med lätthet. Vårt intuitiva användargränssnitt leder dig genom fördefinierade fält, vilket gör det enkelt att ange nödvändig produktinformation samtidigt som branschstandarder upprätthålls.",
                    "generate_qr2": "Intuitivt användargränssnitt.",
                    "generate_qr3": "Generering av unik QR-kod.",
                    "generate_qr4": "Automatisk URL-skapande",
                    "generate_qr5": "Varje QR-kod som genereras är unik för din produkt, tillgänglig i både PNG och SVG-format, vilket säkerställer flexibilitet i dess tillämpning, oavsett om det gäller digitala plattformar eller fysiska etiketter. Dessutom skapas en unik URL automatiskt och inbäddas i QR-koden. Denna URL leder direkt till produktens E-etikett, vilket ger omedelbar tillgång till detaljerad produktinformation för både konsumenter och tillsynsmyndigheter.",
                    "cdl_title": "Skapa en digital etikett",
                    "cdl_1": "Skapandet av en digital etikett är ett viktigt steg för att säkerställa att din produkt uppfyller de senaste regleringskraven och ger tydlig, tillgänglig information till konsumenterna. Vår plattform gör denna process sömlös med fördefinierade fält som leder dig genom de nödvändiga stegen.",
                    "cdl_2": "Fördefinierade regleringsfält.",
                    "cdl_3": "Omfattande datainmatning.",
                    "cdl_4": "Förhandsvisning av etiketter i realtid.",
                    "cdl_5": "Genom att använda våra intuitiva verktyg kan du skapa digitala etiketter som är fullt kompatibla och enkla att uppdatera. Oavsett om du lägger till näringsinformation, ingredienser eller säkerhetsvarningar, säkerställer vår plattform att dina etiketter är korrekta och uppdaterade.",
                    "sfml_title": "Stöd för flera språk",
                    "sfml_1": "På dagens globala marknad är det avgörande att erbjuda produktetiketter på flera språk för att nå en bredare publik. Vår plattform erbjuder robust stöd för att skapa och hantera digitala etiketter på olika språk, vilket säkerställer att din produkt är tillgänglig för konsumenter över hela världen.",
                    "sfml_2": "Stöd för inmatning på flera språk.",
                    "sfml_3": "Sömlös språkväxling.",
                    "sfml_4": "Efterlevnad av regionala regler.",
                    "sfml_5": "Oavsett om din produkt säljs i ett land eller över flera regioner, säkerställer vår plattform att dina digitala etiketter enkelt kan översättas och anpassas för att uppfylla de språkliga och reglerande behoven i varje marknad.",
                    "reg_comp_title": "Regelkompatibilitet",
                    "reg_comp1": "Vår plattform är noggrant utformad för att säkerställa att dina produktetiketter helt följer viktiga EU-förordningar, inklusive EU:s förordning om livsmedelsinformation till konsumenter (EU FIC) - Förordning (EU) Nr 1169/2011, EU:s direktiv om allmän produktsäkerhet (2001/95/EG) och andra relevanta standarder. Dessa förordningar är avgörande för att säkerställa att konsumenter får tydlig, korrekt och omfattande information om de produkter de köper.",
                    "reg_comp2": "Efterlevnad av EU:s förordning om livsmedelsinformation till konsumenter (EU FIC) för tydlig allergen-, närings- och ingrediensinformation.",
                    "reg_comp3": "Följande av EU:s direktiv om allmän produktsäkerhet för att säkerställa att dina produkter är säkra och uppfyller EU:s marknadsstandarder.",
                    "reg_comp4": "Stöd för EU-förordningen om närings- och hälsopåståenden (EG Nr 1924/2006), vilket säkerställer att alla påståenden på dina etiketter är underbyggda och juridiskt förenliga.",
                    "reg_comp5": "Efterlevnad av Förordning (EG) Nr 1223/2009 om kosmetiska produkter, som hjälper dig att uppfylla alla säkerhets- och märkkrav för kosmetiska varor.",
                    "reg_comp6": "Säkerställer att medicintekniska produkter uppfyller märkningsstandarderna enligt EU-förordning 2017/745 om medicintekniska produkter (MDR), vilket säkerställer patientsäkerhet och regelöverensstämmelse.",
                    "reg_comp7": "Genom att använda vår plattform säkerställer du inte bara att dina etiketter är juridiskt förenliga, utan du får också sinnesro och vet att dina produkter uppfyller de högsta standarderna för säkerhet och transparens. Vårt engagemang för regleringskompatibilitet hjälper dig att bygga förtroende med dina konsumenter och bibehålla ditt varumärkes rykte på en konkurrensutsatt marknad.",
                    "reg_comp8": "Börja använda våra verktyg idag för att skapa etiketter som inte bara är förenliga utan också förbättrar kundupplevelsen genom att ge tydlig, tillgänglig information. Tillsammans kan vi navigera genom EU:s regleringskomplexitet och säkerställa att dina produkter alltid är i linje med de senaste standarderna.",
                    "qr_hosting_title": "QR-värdskap",
                    "qr_hosting_1": "QR-kodsvärdskap är en avgörande tjänst som säkerställer att dina digitala etiketter alltid är tillgängliga och uppdaterade. Vår plattform erbjuder pålitliga och säkra värdlösningar för dina QR-koder, vilket säkerställer att de leder till korrekt och aktuell produktinformation.",
                    "qr_hosting_2": "Säker QR-kodsvärdskap.",
                    "qr_hosting_3": "Uppdateringar av data efter skapande.",
                    "qr_hosting_4": "Skalbara värdlösningar.",
                    "qr_hosting_5": "Med våra värdtjänster kan du vara säker på att dina QR-koder alltid leder konsumenterna till den senaste informationen om dina produkter, oavsett var de skannas eller ses.",
                    "service_details_title": "Tjänstedetaljer",
                    "service_details_text": "Utforska vårt tjänsteutbud som är utformat för att möta dina behov och hjälpa ditt företag att växa.",
                    "service_details": "Tjänstedetaljer",
                    "services_list": "Tjänstelista",
                    "download_catalog": "Ladda ner katalog",
                    "have_question_qm": "Har du en fråga?",
                    "password": "Lösenord",
                    "ltt": "Varför din produkt behöver QR-koder?",
                    "lttxt1": "QR-koder ger ett enkelt och effektivt sätt att få detaljerad information om dina produkter. Skanna bara QR-koden med din smartphone för att omedelbart få produktinformation, specialerbjudanden och mer. Våra QR-koder förbättrar kundupplevelsen genom att ge en snabb länk till värdefull information, vilket säkerställer att du får ut det mesta av våra produkter.",
                    "lttxt2": "Vi erbjuder tjänster för att skapa, underhålla och vara värd för QR-koder för att säkerställa att du får en sömlös upplevelse från början till slut.",
                    "forget_your_password": "Glömt ditt lösenord? Kontakta oss",
                    "register_user_account": "Registrera användarkonto",
                    "name_label": "Förnamn",
                    "surname": "Efternamn",
                    "register": "Registrera",
                    "entry_for_this_language_does_not_exist": "Inget inlägg finns för detta språk",
                    "not_in_use_all_caps": "INTE_ANVÄND",
                    "naziv_label": "Namn"
                }
            },

            cs: {
                translation: {

                    "entry_not_found": "Pro vybraný jazyk nebyl nalezen záznam",
                    "create_new_entry_for_selected_language": "Vytvořit nový záznam pro vybraný jazyk",
                    "add_new_product": "Přidat nový produkt",
                    "PHV_UPPERCASE": "PRŮMĚRNÉ NUTRIČNÍ HODNOTY NA 100 ml",
                    "content_uppercase": "OBSAH",
                    "name": "Jméno",
                    "category": "Kategorie",
                    "origin": "Původ",
                    "product_info": "Informace o produktu",
                    "product_name_uppercase": "NÁZEV PRODUKTU",
                    "composition_uppercase": "SEZNAM INGRIDIENCÍ",
                    "details_uppercase": "PODROBNOSTI",
                    "new_entry": "Nový záznam",
                    "entry_list": "Seznam existujících článků",
                    "download_qr": "Stáhnout",
                    "edit": "Upravit",
                    "save": "Uložit",
                    "delete": "Smazat",
                    "cancel": "Zrušit",
                    "yes": "Ano",
                    "no": "Ne",
                    "welcome": "Vítejte",
                    "create_qr_account": "Vytvořit QR účet",
                    "hello": "Ahoj",
                    "login": "Přihlásit se",
                    "register": "Registrovat",
                    "logoff": "Odhlásit se",
                    "frontpage": "Úvodní stránka",
                    "user": "Uživatel",
                    "first_name": "Jméno",
                    "last_name": "Příjmení",
                    "email": "E-MAIL",
                    "qr_account": "QR účet",
                    "creation_date": "Datum vytvoření",
                    "valid_until": "Platí do",
                    "max_qr_codes": "Max. počet QR kódů",
                    "account_active": "Účet aktivní",
                    "impressum": "Impressum",
                    "company": "Společnost",
                    "address": "ADRESA SPOLEČNOSTI",
                    "oib": "DAŇOVÉ ČÍSLO",
                    "phone": "TELEFON",
                    "new_entry": "Nový záznam",
                    "list_existing_items": "Seznam existujících položek",
                    "product_name": "Název produktu",
                    "composition": "Složení",
                    "ingredients": "Složky",
                    "nutrition_value": "Nutriční hodnota",
                    "energy_value_kj": "Energie (kJ)",
                    "energy_value_kcal": "Energie (kcal)",
                    "fat": "Tuky",
                    "energy": "Energie",
                    "saturated_fat": "z toho nasycené tuky",
                    "carbohydrates": "Sacharidy",
                    "sugars": "z toho cukry",
                    "protein": "Protein",
                    "salt": "Sůl",
                    "allergens": "Alergeny",
                    "sulfites": "Siřičitan",
                    "manufacturer": "NÁZEV SPOLEČNOSTI / VÝROBCE",
                    "save": "Uložit",
                    "delete": "Smazat",
                    "cancel": "Zrušit",
                    "edit": "Upravit",
                    "qr_max": "Byl vytvořen maximální počet QR kódů. Pro zvýšení počtu dostupných kódů kontaktujte technickou podporu na danijel@ordonovus.com",
                    "disclamer": "Zobrazené informace jsou povinné informace na základě nařízení (EU) 1308/2013 (společná organizace trhu). Informace poskytuje výrobce. ORDONOVUS poskytuje pouze technické řešení pro splnění této povinnosti. ORDONOVUS neověřuje doručené informace. Prezentované informace poskytla osoba odpovědná za datový soubor jménem výrobce.",
                    "account_not_active": "Účet není aktivní, kontaktujte technickou podporu na danijel@ordonovus.com",
                    "add": "Přidat",
                    "close": "Zavřít",
                    "about_product": "O produktu",
                    "services": "Služby",
                    "features": "Funkce",
                    "pricing": "Ceník",
                    "contact": "Kontakt",
                    "sub_tittle_text": "Digitální etikety pro vaše produkty. Služba generování a správy unikátních QR kódů - digitálních etiket pro potravinářské produkty",
                    "get_started": "Začít",
                    "eu_reg_comp": "Shoda s evropskými předpisy",
                    "labels_for_multiple_langs": "Etikety pro více jazyků",
                    "qr_modification": "Možnost upravit obsah QR etikety",
                    "friendly_interface": "Intuitivní a uživatelsky přívětivé rozhraní",
                    "qr_subtitle_3": "QR kódy - Digitální etikety",
                    "qr_about_t1": "QR kódy od Ordo Novus jsou komplexní řešení pro digitální označování, přizpůsobené potřebám výrobců a distributorů potravin. Naše služba zajišťuje plnou shodu s evropskými předpisy, poskytující spolehlivou a snadno použitelnou platformu pro generování a správu QR kódů. S naším řešením můžete snadno aktualizovat informace o produktech, což zajišťuje, že vaše etikety jsou vždy přesné a aktuální.",
                    "qr_about_t2": "Shoda s předpisy: Plně v souladu s evropskými předpisy (EU č. 1308/2013 a EU č. 1169/2011).",
                    "qr_about_t3": "Flexibilní správa obsahu: Aktualizujte detaily produktu kdykoliv, i po vygenerování QR kódu.",
                    "qr_about_t4": "Uživatelsky přívětivé rozhraní: Intuitivní platforma navržená pro snadné použití, podporující jak chorvatštinu, tak angličtinu.",
                    "check_our": "Podívejte se na naše",
                    "label_example": "Příklad etikety",
                    "label_example_text": "Příklad etikety, která se zobrazí při naskenování jejího QR kódu.",
                    "generating_qr": "Generování QR kódů",
                    "generating_qr_text": "Pro každou položku je vygenerován unikátní QR kód. Je k dispozici ve formátech SVG, PNG a URL, aby pokrylo všechny potřeby od designu až po implementaci pro tisk na fyzické etikety.",
                    "creating_a_digital_label": "Vytváření digitální etikety",
                    "creating_a_digital_label_txt": "Obsah digitální etikety, pro kterou existuje unikátní QR kód, lze upravit podle potřeby i po jejím vytvoření.",
                    "qr_hosting": "QR Hosting",
                    "qr_hosting_text": "Digitální hosting obsahu etikety a údržba odkazu na etiketu a odkazu zakódovaného v unikátním QR kódu.",
                    "regulatory_compl": "Shoda s předpisy",
                    "regulatory_compl_txt": "Naše etikety jsou plně v souladu se všemi relevantními předpisy Evropské unie, včetně nařízení (EU) č. 1308/2013, které upravuje společnou organizaci trhů zemědělských produktů, a nařízení (EU) č. 1169/2011, které zajišťuje poskytování informací o potravinách spotřebitelům.",
                    "multiple_langs": "Podpora více jazyků",
                    "multiple_langs_text": "Etikety jsou k dispozici v několika jazycích, aby byla zajištěna přístupnost a porozumění pro různé spotřebitelské trhy v různých regionech.",
                    "f1": "Shoda s evropskými předpisy",
                    "f2": "Podpora více jazyků",
                    "f3": "Správa obsahu v reálném čase",
                    "f4": "Uživatelsky přívětivé rozhraní",
                    "f5": "Cenová dostupnost",
                    "p1_10": "10 etiket",
                    "p1_40": "40 etiket",
                    "p1_40p": "40+ etiket",
                    "p2_s": "Malí výrobci",
                    "p2_m": "Střední výrobci",
                    "p2_l": "Velcí výrobci a dovozci",
                    "p3": "Technická podpora",
                    "p4": "Uživatelská příručka",
                    "p5": "Aktualizace funkcionalit",
                    "p6": "Více jazyků",
                    "buy_now": "Koupit nyní",
                    "fa": "Často kladené dotazy",
                    "q": "Otázky",
                    "q1": "Co je Nařízení (EU) č. 1169/2011 a jak ovlivňuje označování potravin?",
                    "a1": "Nařízení (EU) č. 1169/2011 zajišťuje, že spotřebitelé mají jasné a komplexní informace o potravinách, které kupují, zejména o složkách, alergenech a výživových hodnotách. Stanoví, že štítky musí být snadno pochopitelné a přístupné, přičemž stanoví specifické požadavky na velikost písma a jazyk​ (Bezpečnost potravin)​ (AGROLAB GROUP).",
                    "q2": "Jak Nařízení (EU) č. 1308/2013 ovlivňuje trhy zemědělských produktů?",
                    "a2": "Nařízení (EU) č. 1308/2013 vytváří společnou organizaci zemědělských trhů v rámci EU, poskytující rámec pro řízení a podporu výroby a obchodu se zemědělskými produkty. Zahrnuje ustanovení o marketingových standardech, krizovém řízení a přímých platbách zemědělcům​ (Bezpečnost potravin)​ (AGROLAB GROUP).",
                    "q3": "Jak QR kódy zlepšují sledovatelnost produktů?",
                    "a3": "QR kódy mohou být propojeny s podrobnými informacemi o produktu, včetně původu, výrobního procesu a certifikací, což usnadňuje spotřebitelům a firmám sledování cesty produktu a zajištění shody s bezpečnostními normami.",
                    "q4": "Jak snadno se náš produkt používá?",
                    "a4": "Náš produkt je navržen s ohledem na uživatelskou přívětivost a disponuje intuitivním rozhraním, které nevyžaduje žádné rozsáhlé technické znalosti. Díky jednoduché navigaci a jasným pokynům mohou uživatelé snadno generovat, spravovat a sledovat QR kódy, což zajišťuje plynulý zážitek.",
                    "q5": "Jaká bezpečnostní opatření jsou zavedena pro QR kódy?",
                    "a5": "QR kódy jsou šifrovány a propojeny s bezpečnými webovými stránkami, což zajišťuje ochranu citlivých informací. Kromě toho mohou být dynamicky generovány, aby se zabránilo neoprávněnému přístupu nebo duplikaci.",
                    "need_help_qm": "Potřebujete pomoc?",
                    "contact_us": "Kontaktujte nás",
                    "bt1": "Proč váš produkt potřebuje QR kódy",
                    "bt2": "QR kódy poskytují jednoduchý a efektivní způsob, jak získat podrobné informace o vašich produktech. Stačí naskenovat QR kód chytrým telefonem a okamžitě získat detaily o produktu. Naše QR kódy zlepšují zákaznický zážitek tím, že poskytují rychlý odkaz na cenné informace, čímž zajistí, že z našich produktů získáte maximum. Nabízíme služby pro tvorbu, údržbu a hostování QR kódů, abyste měli plynulý zážitek od začátku do konce.",
                    "call_us": "Zavolejte nám",
                    "generate_qr_title": "Generování QR kódů",
                    "generate_qr1": "Proces vytváření jedinečného QR kódu pro váš produkt E štítek je navržen tak, aby byl jednoduchý a komplexní, což zajišťuje, že všechny regulační požadavky jsou splněny s lehkostí. Naše intuitivní uživatelské rozhraní vás provede předem definovanými poli, což usnadňuje zadávání potřebných informací o produktu při zachování plné shody s průmyslovými standardy.",
                    "generate_qr2": "Intuitivní uživatelské rozhraní.",
                    "generate_qr3": "Generování unikátního QR kódu.",
                    "generate_qr4": "Automatické vytvoření URL",
                    "generate_qr5": "Každý generovaný QR kód je jedinečný pro váš produkt, dostupný ve formátech PNG a SVG, což zajišťuje flexibilitu v jeho použití, ať už na digitálních platformách nebo fyzických štítcích. Kromě toho je automaticky vytvořena a vložena unikátní URL, která vede přímo na E štítek produktu, čímž poskytuje okamžitý přístup k podrobným informacím o produktu pro spotřebitele i regulátory.",
                    "cdl_title": "Vytváření digitálního štítku",
                    "cdl_1": "Vytvoření digitálního štítku je klíčovým krokem k zajištění, že váš produkt splňuje nejnovější regulační požadavky a poskytuje jasné, přístupné informace spotřebitelům. Naše platforma tento proces usnadňuje s předem definovanými poli, která vás provedou potřebnými kroky.",
                    "cdl_2": "Předdefinovaná regulační pole.",
                    "cdl_3": "Komplexní zadání dat.",
                    "cdl_4": "Náhled štítků v reálném čase.",
                    "cdl_5": "Pomocí našich intuitivních nástrojů můžete vytvářet digitální štítky, které jsou plně v souladu a snadno aktualizovatelné. Ať už přidáváte výživové informace, složky nebo bezpečnostní varování, naše platforma zajišťuje, že vaše štítky jsou přesné a aktuální.",
                    "sfml_title": "Podpora pro více jazyků",
                    "sfml_1": "V dnešním globálním trhu je nezbytné nabízet produktové štítky ve více jazycích, aby se oslovilo širší publikum. Naše platforma poskytuje robustní podporu pro tvorbu a správu digitálních štítků v různých jazycích, čímž zajišťuje, že váš produkt je přístupný spotřebitelům po celém světě.",
                    "sfml_2": "Podpora více jazyků při zadávání.",
                    "sfml_3": "Bezproblémové přepínání jazyků.",
                    "sfml_4": "Shoda s regionálními předpisy.",
                    "sfml_5": "Ať už váš produkt prodáváte v jedné zemi nebo v několika regionech, naše platforma zajišťuje, že vaše digitální štítky mohou být snadno přeloženy a přizpůsobeny k splnění jazykových a regulačních potřeb každého trhu.",
                    "reg_comp_title": "Regulační shoda",
                    "reg_comp1": "Naše platforma je pečlivě navržena tak, aby zajistila, že štítky vašich produktů plně vyhovují klíčovým předpisům EU, včetně Nařízení EU o informacích pro spotřebitele (EU FIC) - Nařízení (EU) č. 1169/2011, Směrnice o všeobecné bezpečnosti výrobků (2001/95/EC) a dalších relevantních standardů. Tato nařízení jsou klíčová pro zajištění, že spotřebitelé dostávají jasné, přesné a komplexní informace o produktech, které kupují.",
                    "reg_comp2": "Shoda s Nařízením EU o informacích pro spotřebitele (EU FIC) pro jasné informace o alergenech, výživových hodnotách a složkách.",
                    "reg_comp3": "Dodržování Směrnice o všeobecné bezpečnosti výrobků pro zajištění bezpečnosti vašich produktů a splnění požadavků trhu EU.",
                    "reg_comp4": "Podpora Nařízení EU o výživových a zdravotních tvrzeních (EC č. 1924/2006), zajišťující, že jakákoli tvrzení na vašich štítcích jsou podložena a právně v souladu.",
                    "reg_comp5": "Shoda s Nařízením (EC) č. 1223/2009 o kosmetických produktech, což vám pomáhá splnit všechny bezpečnostní a označovací požadavky pro kosmetické výrobky.",
                    "reg_comp6": "Zajištění, že zdravotnické prostředky splňují označovací normy stanovené Nařízením EU 2017/745 o zdravotnických prostředcích (MDR), což zajišťuje bezpečnost pacientů a regulační shodu.",
                    "reg_comp7": "Používáním naší platformy nejen zajistíte, že vaše štítky jsou právně v souladu, ale také získáte klid, protože víte, že vaše produkty splňují nejvyšší standardy bezpečnosti a transparentnosti. Naše závazek k regulační shodě vám pomáhá budovat důvěru se spotřebiteli a udržet reputaci vaší značky na konkurenčním trhu.",
                    "reg_comp8": "Začněte využívat naše nástroje již dnes k vytváření štítků, které nejsou pouze v souladu, ale také zlepšují zážitek spotřebitelů tím, že poskytují jasné, přístupné informace. Společně můžeme překonat složitosti evropských regulací a zajistit, že vaše produkty budou vždy v souladu s nejnovějšími standardy.",
                    "qr_hosting_title": "Hostování QR kódů",
                    "qr_hosting_1": "Hostování QR kódů je klíčová služba, která zajišťuje, že vaše digitální štítky jsou vždy přístupné a aktuální. Naše platforma poskytuje spolehlivá a bezpečná hostovací řešení pro vaše QR kódy, což zajišťuje, že vedou k správným a aktuálním informacím o produktu.",
                    "qr_hosting_2": "Bezpečné hostování QR kódů.",
                    "qr_hosting_3": "Aktualizace dat po vytvoření.",
                    "qr_hosting_4": "Škálovatelné hostovací řešení.",
                    "qr_hosting_5": "Díky našim hostingovým službám si můžete být jisti, že vaše QR kódy vždy nasměrují spotřebitele na nejnovější informace o vašich produktech, bez ohledu na to, kde jsou skenovány nebo zobrazeny.",
                    "service_details_title": "Podrobnosti služby",
                    "service_details_text": "Prozkoumejte naši nabídku služeb navrženou tak, aby vyhověla vašim potřebám a pomohla vašemu podnikání růst.",
                    "service_details": "Podrobnosti služby",
                    "services_list": "Seznam služeb",
                    "download_catalog": "Stáhnout katalog",
                    "have_question_qm": "Máte otázku?",
                    "password": "Heslo",
                    "ltt": "Proč váš produkt potřebuje QR kódy?",
                    "lttxt1": "QR kódy poskytují snadný a efektivní způsob, jak získat podrobné informace o vašich produktech. Stačí naskenovat QR kód chytrým telefonem a okamžitě získat detaily o produktu, speciální nabídky a další. Naše QR kódy zlepšují zákaznický zážitek tím, že poskytují rychlý odkaz na cenné informace, čímž zajistí, že z našich produktů získáte maximum.",
                    "lttxt2": "Nabízíme služby pro tvorbu, údržbu a hostování QR kódů, abyste měli plynulý zážitek od začátku do konce.",
                    "forget_your_password": "Zapomněli jste heslo? Kontaktujte nás",
                    "register_user_account": "Registrovat uživatelský účet",
                    "name_label": "Jméno",
                    "surname": "Příjmení",
                    "register": "Registrovat",
                    "entry_for_this_language_does_not_exist": "Záznam pro tento jazyk neexistuje",
                    "not_in_use_all_caps": "NEPOUŽÍVÁ SE",
                    "naziv_label": "Jméno"

                }
            },
        },
        fallbackLng: 'en',
        interpolation: {
            escapeValue: false // React already escapes by default
        }
    });

export default i18n;
