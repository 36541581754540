import React from 'react';
import QRCode from 'qrcode.react';
import { useTranslation } from 'react-i18next';

const QRCodeGenerator = ({ maskedId, name, itemid, det }) => {
    const { t } = useTranslation();

    //const downloadQRCodeAsPNG = () => {
    //    const canvas = document.getElementById(`qrCodeCanvas-${maskedId}`);
    //    const pngUrl = canvas
    //        .toDataURL("image/png")
    //        .replace("image/png", "image/octet-stream");
    //    let downloadLink = document.createElement("a");
    //    downloadLink.href = pngUrl;
    //    downloadLink.download = `qrCode-${name}.png`;
    //    document.body.appendChild(downloadLink);
    //    downloadLink.click();
    //    document.body.removeChild(downloadLink);
    //};

    const downloadQRCodeAsPNG = () => {
        const svgElement = document.getElementById(`qrCodeSVG-${maskedId}`);
        if (!svgElement) {
            console.error('SVG element not found');
            return;
        }
    
        // Create a canvas
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
    
        const svgData = new XMLSerializer().serializeToString(svgElement);
        const svgBlob = new Blob([svgData], { type: 'image/svg+xml;charset=utf-8' });
        const svgUrl = URL.createObjectURL(svgBlob);
    
        const img = new Image();
        img.onload = () => {
            canvas.width = img.width;
            canvas.height = img.height;
            ctx.drawImage(img, 0, 0);
    
            URL.revokeObjectURL(svgUrl); // Release the object URL after drawing on canvas
    
            // Now download the canvas as a PNG
            const pngUrl = canvas
                .toDataURL("image/png")
                .replace("image/png", "image/octet-stream");
            let downloadLink = document.createElement("a");
            downloadLink.href = pngUrl;
            downloadLink.download = `qrCode-${name}.png`;
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
        };
    
        img.src = svgUrl;
    };

    const downloadQRCodeAsSVG = () => {
        const svgElement = document.getElementById(`qrCodeSVG-${maskedId}`);
        if (!svgElement) {
            console.error('SVG element not found');
            return;
        }
        
        // Serialize the SVG node to string
        const serializer = new XMLSerializer();
        const svgData = serializer.serializeToString(svgElement);

        // Create a Blob from the SVG data and initiate download
        const svgBlob = new Blob([svgData], { type: "image/svg+xml;charset=utf-8" });
        const svgUrl = URL.createObjectURL(svgBlob);
        let downloadLink = document.createElement("a");
        downloadLink.href = svgUrl;
        downloadLink.download = `qrCode-${name}.svg`;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    };

    const trim_mask = Number(maskedId);
    const trim_id = Number(itemid);

    const qrData = `https://tinyurl.com/4p99zes8/${trim_id}/${trim_mask}/`

    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', minHeight: "450px", marginTop: "-10px" }}>
            <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
                <button className="btn btn-primary mt-4" style={{width:"150px", fontSize: "14px"}}  onClick={() => det(trim_mask)}>{t('edit')}</button>
            </div>
            <div style={{ border: '1px solid #001a00', borderRadius: '5px', backgroundColor: "white" }}>
                <QRCode
                    id={`qrCodeSVG-${maskedId}`} // ID for SVG rendering
                    value={qrData}
                    size={300}
                    level={"L"}
                    includeMargin={true}
                    renderAs="svg" // Render as SVG
                    style={{ borderRadius: "5px" }}
                />
                <canvas
                    id={`qrCodeCanvas-${maskedId}`} // ID for Canvas rendering
                    style={{ display: 'none' }} // Hide the canvas element
                />
            </div>
            <p style={{ fontSize: "14px" }}>{qrData}</p>
            <div style={{ display: 'flex', gap: '10px', marginTop: "-15px", fontSize: "16px" }}>
                    <button className="btn btn-primary mt-4" onClick={downloadQRCodeAsPNG} style={{ display: 'flex', gap: '10px', marginTop: "-15px", fontSize: "14px" }}>{t('download_qr')} PNG</button>
                    <button className="btn btn-primary mt-4" onClick={downloadQRCodeAsSVG} style={{ display: 'flex', gap: '10px', marginTop: "-15px", fontSize: "14px" }}>{t('download_qr')} SVG</button>
            </div>
        </div>
    );
};

export default QRCodeGenerator;
